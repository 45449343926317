import { MutableRefObject, useEffect, useRef } from "react";
import { State } from "../state";
import { InternalAction } from "../core/store/action";

export interface KeyboardHandler {
  action: InternalAction;
  cond: (state: State) => Boolean;
  capturing: boolean;
}

export const createHandler = (
  action: InternalAction,
  cond: (state: State) => Boolean = (s) => true,
  capturing: boolean = true
) => ({ action, cond, capturing });

export const keyboardEventToKey = (e: KeyboardEvent) => {
  return [
    e.shiftKey ? "Shift" : null,
    e.altKey ? "Alt" : null,
    e.ctrlKey ? "Ctrl" : null,
    e.metaKey ? "Meta" : null,
    e.key,
  ]
    .filter((s) => s !== null)
    .join("+");
};

export const createKeyHandlerMap = (
  mappings: Array<{ key: string; handler: KeyboardHandler }>
): Map<String, KeyboardHandler> => {
  const map = new Map<String, KeyboardHandler>();
  mappings.forEach((mapping) => {
    map.set(mapping.key, mapping.handler);
  });
  return map;
};

export const useKeyHandler = (
  keyHandlerMap: Map<String, KeyboardHandler>,
  dispatch: ({ type: string, payload: any }) => void,
  state: State
) => {
  const listener: MutableRefObject<
    ((this: Window, ev: KeyboardEvent) => any) | undefined
  > = useRef();

  useEffect(() => {
    if (listener.current !== undefined) {
      window.removeEventListener("keydown", listener.current);
    }

    listener.current = function(e: KeyboardEvent) {
      const key = keyboardEventToKey(e);
      const handler = keyHandlerMap.get(key);
      if (handler && handler.cond(state)) {
        dispatch(handler.action);
        if (handler.capturing) {
          e.preventDefault();
        }
      }
    };

    window.addEventListener("keydown", listener.current);

    return () =>
      listener.current &&
      window.removeEventListener("keydown", listener.current);
  }, [keyHandlerMap, state, dispatch]);
};
