import { RefObject, useEffect } from "react";
import { EditingState } from "../model/editing-state";

export const useEditStateFocus = (
  editState: EditingState,
  id: string,
  note: RefObject<HTMLDivElement>,
  expanded: RefObject<HTMLDivElement>,
  deps: any[]
) => {
  useEffect(() => {
    if (editState.state === "none" || editState.id !== id) {
      return;
    } else {
      setTimeout(() => {
        const ref = editState.state === "note" ? note : expanded;
        if (ref.current) {
          focusEl(ref.current);
        }
      }, 16);
    }
  }, [editState, note, expanded, id, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};

function focusEl(el: HTMLElement) {
  const s: Selection | null = window.getSelection();
  if (s == null || (s.focusNode && s.focusNode.parentElement === el)) {
    return;
  }
  const r = document.createRange();
  r.selectNodeContents(el);
  r.collapse();
  s!.removeAllRanges();
  s!.addRange(r);
}
