import { ConfigurationItem } from "../state/plugin/configuration-item";

import { InternalAction } from "./store/action";

export default function configItemMap(): Map<string, ConfigurationItem[]> {
  const map = new Map<string, ConfigurationItem[]>();
  map.set("Bulletime Core", [
    {
      kind: "string",
      label: "Background Color",
      key: "core.backgroundColor",
      pattern: /#[0-9A-Fa-f]{6}/,
      defaultVal: "#000000",
      description: "The background color for the application",
      action: (color: string): InternalAction => {
        return {
          type: "setBackgroundColor",
          payload: { color },
        };
      },
    },
    {
      kind: "string",
      label: "Primary Text Color",
      key: "core.foregroundColor",
      pattern: /#[0-9A-Fa-f]{6}/,
      defaultVal: "#FFFFFF",
      description: "The primary color for text in the application",
      action: (color: string) => ({
        type: "setForegroundColor",
        payload: { color },
      }),
    },
    {
      kind: "string",
      label: "Secondary Text Color",
      key: "core.secondaryColor",
      pattern: /#[0-9A-Fa-f]{6}/,
      defaultVal: "#999999",
      description: "The secondary color for text in the application",
      action: (color: string) => ({
        type: "setSecondaryColor",
        payload: { color },
      }),
    },
    {
      kind: "stringOpts",
      label: "Font",
      key: "core.font",
      defaultVal: document.documentElement.style.getPropertyValue("--uiFont"),
      options: [
        document.documentElement.style.getPropertyValue("--uiFont"),
        "Roboto",
        "Input Mono",
        "sans",
        "serif",
      ],
      description: "The font used for text in the application",
      action: (font: string) => ({
        type: "setFont",
        payload: { font },
      }),
    },
    {
      kind: "keyboardShortcut",
      label: "Test",
      key: "core.testKeyboard",
      defaultVal: "",
      description: "This is just a test",
      action: (shortcut: string) => ({
        type: "setTestShortcut",
        payload: { shortcut },
      }),
    },
  ]);

  return map;
}
