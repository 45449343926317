import { createHandler } from "../keyboard";

export default [
  {
    key: "Meta+j",
    handler: createHandler({ type: "prevWindow", payload: undefined }),
  },
  {
    key: "Meta+k",
    handler: createHandler({ type: "nextWindow", payload: undefined }),
  },
  {
    key: "Ctrl+Meta+n",
    handler: createHandler({ type: "newWindow", payload: undefined }),
  },
  {
    key: "Meta+/",
    handler: createHandler({ type: "showWindowSwitcher", payload: undefined }),
  },
  {
    key: "Escape",
    handler: createHandler(
      { type: "hideModal", payload: undefined },
      (state) => state.modal !== undefined
    ),
  },
  {
    key: "Meta+,",
    handler: createHandler({ type: "toggleConfigPanel", payload: undefined }),
  },
];
