import Constants from "./constants";
import { PLUGIN } from "../constants";

const pluginType = (type: string) => ({
  plugin: PLUGIN,
  contribution: "view",
  type,
});

export default {
  noteSelected: (id) => ({
    type: pluginType(Constants.selectedNote),
    payload: id,
  }),
  toggleCollapsed(id) {
    return {
      type: pluginType(Constants.toggleCollapsed),
      payload: id,
    };
  },
  noteUpdated(id: string, note: string) {
    return {
      type: pluginType(Constants.noteUpdated),
      payload: { id, note },
    };
  },
  expandedUpdated(id: string, expanded: string) {
    return {
      type: pluginType(Constants.expandedUpdated),
      payload: {
        id,
        expanded,
      },
    };
  },
  addExpandedAtRoot() {
    return {
      type: pluginType(Constants.addExpandedAtRoot),
      payload: null,
    };
  },
  expandedSelected(id: string) {
    return {
      type: pluginType(Constants.selectedExpanded),
      payload: {
        id,
      },
    };
  },
  toggleNoteExpanded() {
    return {
      type: pluginType(Constants.toggleNoteExpanded),
      payload: null,
    };
  },
  indent() {
    return {
      type: pluginType(Constants.indent),
      payload: null,
    };
  },
  dedent() {
    return {
      type: pluginType(Constants.dedent),
      payload: null,
    };
  },
  toggleCompleted() {
    return {
      type: pluginType(Constants.toggleCompleted),
      payload: null,
    };
  },
  moveUp() {
    return {
      type: pluginType(Constants.moveUp),
      payload: null,
    };
  },
  moveDown() {
    return {
      type: pluginType(Constants.moveDown),
      payload: null,
    };
  },
  navUp() {
    return {
      type: pluginType(Constants.navUp),
      payload: null,
    };
  },
  navDown() {
    return {
      type: pluginType(Constants.navDown),
      payload: null,
    };
  },
  showBookmarksModal() {
    return {
      type: pluginType(Constants.showBookmarksModal),
      payload: null,
    };
  },
  toggleBookmark(id: string) {
    return {
      type: pluginType(Constants.toggleBookmark),
      payload: { id },
    };
  },
};
