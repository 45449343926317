export enum ContributionType {
  view,
  modifier,
  theme,
  service,
  provider
}

const contribKeyMap = (() => {
  const map = new Map<ContributionType, string>();
  map.set(ContributionType.view, "view");
  map.set(ContributionType.modifier, "modifier");
  map.set(ContributionType.theme, "theme");
  map.set(ContributionType.service, "service");
  map.set(ContributionType.provider, "provider");

  return map;
})();

export const contributionTypeToKey = (type: ContributionType): string => {
  return contribKeyMap.get(type) || "unknown";
};
