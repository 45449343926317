import { RefObject, useEffect } from "react";
import { EditingState } from "../model/editing-state";

export const useEditStateScroll = (
  editState: EditingState,
  id: string,
  note: RefObject<HTMLDivElement>,
  expanded: RefObject<HTMLDivElement>,
  deps: any[] = []
) => {
  useEffect(() => {
    if ((editState && editState.state === "none") || editState.id !== id) {
      return;
    } else {
      setTimeout(() => {
        const ref = editState.state === "note" ? note : expanded;
        if (ref.current === undefined) {
          return;
        }
        const el = ref.current;
        if (el === null) {
          return;
        }
        const offTheBottom = () =>
          el.offsetTop > window.scrollY + window.innerHeight;
        const offTheTop = () => el.offsetTop < window.scrollY;
        if (offTheBottom() || offTheTop()) {
          window.scrollTo(0, el.offsetTop);
        }
      }, 16);
    }
  }, [note, id, expanded, editState, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};
