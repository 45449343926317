import React from "react";
import { Bullet as B, BulletTree as BT } from "../../../bulletime-ds";
import styles from "../../styles/modal.module.css";

interface Props {
  bookmarks: Array<{ id: string; note: string }>;
  dispatch: Function;
}

const BookmarksModal = ({ bookmarks, dispatch }: Props) => (
  <div className={styles.dialog}>
    <div className={styles.header}>Bookmarks:</div>
    <ul>
      {bookmarks.map((bm) => (
        <li
          onClick={() => dispatch({ type: "focusOn", payload: bm.id })}
          key={bm.id}
        >
          {bm.note}
        </li>
      ))}
    </ul>
  </div>
);

export default ({ state, dispatch }) => {
  const ids = state.storage.bookmarks;
  const zip = BT.fromTree(state.canonical);
  const bookmarks = ids.map((id) => {
    const atId = BT.to(id, zip);
    return { id, note: B.note(BT.current(atId)) };
  });
  return <BookmarksModal bookmarks={bookmarks} dispatch={dispatch} />;
};
