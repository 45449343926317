// Bullet
// ---
// Core data structure for Bulletime.
// Represents a node in a tree structure.
//
// It's internal structure is considered to be opaque, use the exported
// API to work with it.

import { v4 } from "uuid";

// "Type" constructors

type BulletId = string;
type Millis = number;

interface Meta {
  readonly id: BulletId;
  readonly timeCreated: Millis;
  readonly lastModified: Millis;
  readonly plugins: { [pluginName: string]: { [keyName: string]: any } };
}

export interface Bullet {
  readonly meta: Meta;
  readonly note: string;
  readonly expanded: string | undefined;
}

const Meta = (): Meta => ({
  id: v4(),
  timeCreated: Date.now(),
  lastModified: Date.now(),
  plugins: {}
});

const Bullet = (note: string = "", expanded?: string | undefined): Bullet => ({
  meta: Meta(),
  note,
  expanded
});

// Initializers

export const bullet = Bullet;

// Access

export const note = ({ note }: Bullet): string => note;
export const expanded = ({ expanded }: Bullet): string | undefined => expanded;
export const id = ({ meta }: Bullet): BulletId => meta.id;
export const lastModified = ({ meta }: Bullet): Millis => meta.lastModified;
export const timeCreated = ({ meta }: Bullet): Millis => meta.timeCreated;
export const pluginMeta = (
  plugin: string,
  key: string,
  { meta }: Bullet
): any | undefined => {
  return meta.plugins[`${plugin}.${key}`] || undefined;
};

// Update

type Mapper<T> = (t: T) => T;

export const replaceNote = (note: string, bullet: Bullet): Bullet =>
  update({ ...bullet, note });

export const mapNote = (fn: Mapper<string>, bullet: Bullet): Bullet =>
  replaceNote(fn(bullet.note), bullet);

export const replaceExpanded = (
  expanded: string | undefined,
  bullet: Bullet
): Bullet => update({ ...bullet, expanded });

export const mapExpanded = (
  fn: Mapper<string | undefined>,
  bullet: Bullet
): Bullet => replaceExpanded(fn(bullet.expanded), bullet);

export const replacePluginMeta = <T>(
  newMeta: T,
  plugin: string,
  key: string,
  bullet: Bullet
): Bullet =>
  update({
    ...bullet,
    meta: {
      ...bullet.meta,
      plugins: {
        ...bullet.meta.plugins,
        [`${plugin}.${key}`]: newMeta
      }
    }
  });

export const mapPluginMeta = <T>(
  fn: Mapper<T>,
  plugin: string,
  key: string,
  bullet: Bullet
): Bullet =>
  update({
    ...bullet,
    meta: {
      ...bullet.meta,
      plugins: {
        ...bullet.meta.plugins,
        [`${plugin}.${key}`]: fn(pluginMeta(plugin, key, bullet))
      }
    }
  });

// Debug

export const display = (
  bullet: Bullet,
  level: number,
  isFocus: boolean
): string => {
  let tabs = "";
  for (let x = 0; x < level; x++) {
    tabs += "\t";
  }

  const indicator = isFocus ? ">" : "*";
  const noteDisplay = `${tabs}${indicator} ${note(bullet)}`;

  return expanded(bullet) === undefined
    ? noteDisplay
    : [noteDisplay, tabs + `  ${expanded(bullet)}`].join("\n");
};

// Internal

const update = (updated: Bullet): Bullet => ({
  ...updated,
  meta: {
    ...updated.meta,
    lastModified: Date.now()
  }
});
