import React, { memo, useMemo } from "react";
import { Bullet as B } from "../../../../../../bulletime-ds";
import * as T from "../../../../../../rosetree/tree";
import {
  ViewRootComponent,
  ViewRootComponentProps,
} from "../../../../../../state/plugin/contributions/view";
import * as Z from "../../../../../../rosetree/zipper";
import creators from "../../../actions/creators";
import { MetaKeys } from "../../../actions/MetaKeys";
import { PLUGIN } from "../../../constants";
import useMappedTree from "../../../hooks/use-mapped-tree";
import { useToTopIndicator } from "../../../hooks/use-to-top-indicator";
import {
  fromTree,
  nodeDepth,
  nodeLabel,
  window as ftwindow,
} from "../../../model/flat-tree-window";
import { BulletimeCoreState, defaultState } from "../../../model/state";
import { Bullet } from "../Bullet";
import { Root } from "../Root";
import styles from "./index.module.css";

const BulletimeCore: ViewRootComponent = ({
  state,
  dispatch,
}: ViewRootComponentProps) => {
  const tree = state.tree;
  const id = B.id(T.label(tree));
  const { editState } = (state.storage[id] ||
    defaultState) as BulletimeCoreState;

  const bookmarks: string[] = state.storage["BOOKMARKS"] || [];

  const rootIsBookmarked = bookmarks.includes(id);

  const mappedTree = useMappedTree(tree);
  const ftw = useMemo(() => fromTree(Z.toTree(mappedTree)), [mappedTree]);
  const [w] = ftwindow(ftw);

  const shouldShowUpIndicator = useToTopIndicator(window.innerHeight / 3);

  const root = w[0];

  return (
    <div id={"bulletime-core-root"} className={styles.BulletimeCoreRoot}>
      {shouldShowUpIndicator ? (
        <div
          className={styles.upIndicator}
          onClick={() => window.scrollTo(0, 0)}
        >
          <i className="material-icons">vertical_align_top</i>
        </div>
      ) : null}
      <Root
        key={B.id(nodeLabel(root))}
        node={root}
        editState={editState}
        dispatch={dispatch}
        isBookmarked={rootIsBookmarked}
      ></Root>
      {w.slice(1).map((node, idx, arr) => (
        <Bullet
          key={B.id(nodeLabel(node))}
          node={node}
          dispatch={dispatch}
          hasChildren={nodeHasChildren(node, idx, arr)}
          bulletEditState={editState}
        ></Bullet>
      ))}
      <div
        id={"bulletime-core-mobile-controls"}
        className={styles.mobileControlBar}
      >
        {editState.state === "expanded" ? (
          <button
            onClick={() => {
              dispatch(creators.toggleNoteExpanded());
            }}
          >
            <i className="material-icons">height</i>
          </button>
        ) : editState.state === "note" ? (
          <>
            <button
              onClick={() => {
                dispatch(creators.dedent());
              }}
            >
              <i className="material-icons">keyboard_arrow_left</i>
            </button>
            <button
              onClick={() => {
                dispatch(creators.indent());
              }}
            >
              <i className="material-icons">keyboard_arrow_right</i>
            </button>
            <button
              onClick={() => {
                dispatch(creators.moveUp());
              }}
            >
              <i className="material-icons">keyboard_arrow_up</i>
            </button>
            <button
              onClick={() => {
                dispatch(creators.moveDown());
              }}
            >
              <i className="material-icons">keyboard_arrow_down</i>
            </button>
            <button
              onClick={() => {
                dispatch(creators.toggleNoteExpanded());
              }}
            >
              <i className="material-icons">height</i>
            </button>
            <button
              onClick={() => {
                dispatch(creators.toggleCompleted());
              }}
            >
              <i className="material-icons">done</i>
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};

function nodeHasChildren(
  node: [number, B.Bullet],
  idx: number,
  arr: Array<[number, B.Bullet]>
): boolean {
  // If at the end of the array, false
  // If collapsed, assume true
  // Other, true if next node has higher depth
  if (B.pluginMeta(PLUGIN, MetaKeys.collapsed, nodeLabel(node))) {
    return true;
  }
  if (idx + 1 === arr.length) {
    return false;
  }
  return nodeDepth(node) < nodeDepth(arr[idx + 1]);
}

export default memo(
  BulletimeCore,
  ({ state: oldState }, { state }) => oldState === state
);
