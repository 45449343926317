import React, { useState } from "react";

interface BookmarkProps {
  bookmarked: boolean;
  onClick: VoidFunction;
}

const Bookmark = ({ bookmarked, onClick }: BookmarkProps) => {
  const [hovered, setHovered] = useState(false);
  const filledIn = bookmarked || hovered;

  return (
    <i
      className={"material-icons"}
      style={{ fontSize: "32px", margin: "auto" }}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {filledIn ? "bookmark" : "bookmark_outline"}
    </i>
  );
};

export { Bookmark };
