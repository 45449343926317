// Bullet Tree
// ---
// The core data structure of the application state
// Exposes the API for working with a Zipper<Bullet>
import * as B from "../bullet";
import * as T from "../../rosetree/tree";
import { partial } from "../../utils/function";
import { Maybe, pipe } from "../../utils/maybe";
import * as Z from "../../rosetree/zipper";

export type BulletTree = Z.Zipper<B.Bullet>;

export const init = (): BulletTree => Z.fromTree(T.singleton(B.bullet()));
export const fromTree = (t: T.Tree<B.Bullet>): BulletTree => Z.fromTree(t);

export const toTree = Z.toTree;

export const forward = (z: BulletTree): Maybe<BulletTree> => Z.forward(z);
export const backward = (z: BulletTree): Maybe<BulletTree> => Z.backward(z);
export const up = (z: BulletTree): Maybe<BulletTree> => Z.previousSibling(z);
export const down = (z: BulletTree): Maybe<BulletTree> => Z.nextSibling(z);
export const top = (z: BulletTree): BulletTree => Z.root(z);
export const bottom = (z: BulletTree): BulletTree =>
  Z.lastDescendant(Z.root(z));
export const to = (id: String, z: BulletTree): BulletTree =>
  Z.findFromRoot(bullet => B.id(bullet) === id, z) || z;

export const moveUp = (z: BulletTree): BulletTree => {
  // If there is a previous sibling, swap positions
  if (Z.previousSibling(z)) {
    return (
      pipe(z, [
        Z.removeTree,
        partial(Z.prepend, Z.tree(z)),
        Z.previousSibling
      ]) || z
    );
  } else {
    return z;
  }
};

export const moveDown = (z: BulletTree): BulletTree => {
  // If there is a next sibling, swap positions
  if (Z.nextSibling(z)) {
    // When we remove the tree, we will move to the parent, must move forward first
    if (Z.previousSibling(z) === undefined) {
      return (
        pipe(z, [Z.removeTree, forward, partial(Z.append, Z.tree(z)), down]) ||
        z
      );
    }
    return (
      pipe(z, [Z.removeTree, down, partial(Z.append, Z.tree(z)), down]) || z
    );
  } else {
    return z;
  }
};

export const addSibling = (z: BulletTree): BulletTree =>
  pipe(Z.append(T.singleton(B.bullet()), z), [down]) || z;

export const addChild = (z: BulletTree): BulletTree =>
  addTreeAsChild(T.singleton(B.bullet()), z);

export const addTreeAsChild = (
  tree: T.Tree<B.Bullet>,
  z: BulletTree
): BulletTree =>
  pipe(
    Z.mapTree((t: T.Tree<B.Bullet>) => T.prependChild(tree, t), z),
    [Z.firstChild]
  ) || z;

export const indent = (z: BulletTree): BulletTree => {
  // If previous sibling, become last child of it
  if (Z.previousSibling(z)) {
    const tree: T.Tree<B.Bullet> = Z.tree(z);
    const removed: Maybe<BulletTree> = Z.removeTree(z);
    if (removed === undefined) {
      return z;
    }
    return addTreeAsChild(tree, removed) || z;
  } else {
    return z;
  }
};

// If has parent, that is not root, make next sibling of it
export const dedent = (z: BulletTree): BulletTree => {
  const tree = Z.tree(z);
  const removed = Z.removeTree(z);
  if (removed === undefined) {
    return z;
  }

  if (Z.previousSibling(z)) {
    return pipe(removed, [Z.parent, partial(Z.append, tree), down]) || z;
  } else {
    return pipe(removed, [partial(Z.append, tree), down]) || z;
  }
};

export const remove = (z: BulletTree): BulletTree => Z.removeTree(z) || z;

export const updateNote = (note: string, z: Z.Zipper<B.Bullet>) =>
  Z.mapLabel<B.Bullet>(bullet => B.replaceNote(note, bullet), z);

export const updateExpanded = (
  expanded: Maybe<string>,
  z: BulletTree
): BulletTree => Z.mapLabel(bullet => B.replaceExpanded(expanded, bullet), z);

export const createExpanded = (z: BulletTree): BulletTree =>
  Z.mapLabel(
    bullet =>
      B.mapExpanded(
        maybeExpanded => (maybeExpanded === undefined ? "" : maybeExpanded),
        bullet
      ),
    z
  );

export const updateMeta = (
  meta: any,
  plugin: string,
  key: string,
  z: BulletTree
): BulletTree =>
  Z.mapLabel(bullet => B.mapPluginMeta(() => meta, plugin, key, bullet), z);

export const current = (z: BulletTree): B.Bullet => Z.label(z);
export const breadcrumbs = (z: BulletTree) =>
  z.crumbs.map(crumb => ({
    title: B.note(crumb.label),
    id: B.id(crumb.label)
  }));

// Debug

// The display format is useful mostly for debugging and testing, as it
// abstracts away some details of the Bullet that may not be useful for
// those purposes.
export const display = (z: BulletTree): string =>
  Z.display<B.Bullet>(z, B.display);

export const fromDisplay = (s: String): BulletTree => {
  const lines = s
    .trim()
    .split("\n")
    .map((line, idx) => toDisplayLine(line.trimEnd(), idx));
  const z = innerFromDisplay(undefined, lines);

  // Find the focus on the built tree
  return lines.reduce(
    (
      [z_, reachedFocus]: [BulletTree, boolean],
      curr: DisplayLine
    ): [BulletTree, boolean] => {
      if (reachedFocus) {
        return [z_, true];
      } else {
        return curr.type === "focus"
          ? [z_, true]
          : [Z.forward(z_) || Z.root(z), false];
      }
    },
    [Z.root(z), false]
  )[0];
};
const innerFromDisplay = (
  z: Maybe<BulletTree>,
  remaining: DisplayLine[]
): BulletTree => {
  // We parse the string by chomping first the note, then the expanded until
  // we encounter another note.
  // We then build the bullet out of the strings, and manipulate the tree to
  // place it appropriately based on the number of tabs
  if (z === undefined) {
    // This is the first line, so we need create the first bullet and create the zipper.
    if (remaining.length === 0) {
      // This must have been an empty string, which is invalid.
      throw new Error(`Cannot parse empty display string`);
    }

    // Now that we have something to work with, let's make sure that the first item
    // is at the root level
    if (remaining[0].tabs > 0) {
      throw new Error("Parsing Error at line 0: Root is not at 0th level");
    }

    try {
      // Now we'll chomp lines from remaining until we reach the next bullet;
      const [bullet, left] = linesToBullet(remaining);
      // Now recurse with the initialized tree and the remaining lines
      return innerFromDisplay(Z.fromTree(T.singleton(bullet)), left);
    } catch (err) {
      // if we have an error creating the bullet we'll throw
      throw new Error(`Parsing error: ${(err as Error).message}`);
    }
  } else {
    // This means we are past the root.
    // If that was the only bullet, we're done.
    if (remaining.length === 0) {
      return z;
    }

    const currLevel = Z.depth(z);
    const nextLevel = remaining[0].tabs;

    // No other element may be at the root level, so we'll throw here if that is so.
    if (remaining[0].tabs === 0) {
      throw new Error(
        `Parsing Error: Cannot have multiple roots\n----\n${remaining[0]}`
      );
    }

    try {
      const [bullet, left] = linesToBullet(remaining);
      if (currLevel === nextLevel) {
        // If at the same level as the last added item, it is a sibling.
        // So, we'll append it and then move to it.

        const next = pipe(z, [partial(Z.append, T.singleton(bullet)), down]);

        if (next === undefined) {
          throw new Error("Building Error: Should never happen");
        }

        if (bullet !== current(next)) {
          throw new Error(
            "We didn't end with focus on the bullet we just created"
          );
        }

        return innerFromDisplay(next, left);
      } else if (currLevel < nextLevel) {
        // If the last item is at a lower level than this one, this should be the first child
        // There should only be a difference of 1 in the level
        if (nextLevel - currLevel > 1) {
          throw new Error(`Bullet with no obvious parent ${B.note(bullet)}`);
        }

        const next = addTreeAsChild(T.singleton(bullet), z);

        if (next === undefined) {
          throw new Error("Building Error: Should never happen");
        }

        if (bullet !== current(next)) {
          throw new Error(
            "We didn't end with focus on the bullet we just created"
          );
        }

        return innerFromDisplay(next, left);
      } else {
        // If none of the above applies, this must be a sibling of a parent
        const levelDifference = currLevel - nextLevel;
        const parentMoves = [...Array(levelDifference)].map(_ => Z.parent);
        const next = pipe(z, [
          ...parentMoves,
          partial(Z.append, T.singleton(bullet)),
          down
        ]);

        if (next === undefined) {
          throw new Error("Building Error: Should never happen");
        }
        if (bullet !== current(next)) {
          throw new Error(
            "We didn't end with focus on the bullet we just created"
          );
        }
        return innerFromDisplay(next, left);
      }
    } catch (e) {
      console.error(`Building Error: ${e.message}\n${e.stack}`);
    }
  }

  return z;
};

const linesToBullet = (lines: DisplayLine[]): [B.Bullet, DisplayLine[]] => {
  const [curr, left] = [lines[0], lines.slice(1)];
  if (curr.type === "expanded") {
    // This is not valid, we should have a note of some sort
    throw new Error("Unexpected `expanded` section with no note.");
  }
  const chompUntil = left.findIndex(
    line =>
      line.tabs !== curr.tabs ||
      line.type === "focus" ||
      line.type === "unfocus"
  );

  const expanded = (chompUntil >= 0 ? left.slice(0, chompUntil) : left)
    .map(line => line.val)
    .join(" ");
  const bullet = B.bullet(curr.val, expanded === "" ? undefined : expanded);
  return [bullet, chompUntil >= 0 ? left.slice(chompUntil) : []];
};

type DisplayLine = {
  type: "focus" | "unfocus" | "expanded";
  tabs: number;
  val: string;
};

const toDisplayLine = (s: String, idx: number): DisplayLine => {
  // A regular tree is some amount of tabs followed by either of the following sequences:
  // *[space] - An unfocused tree
  // >[space] - A focused tree
  // [space][space] - An expanded section
  const chars = Array.from(s);
  const nonCharIndex = chars.findIndex(c => c !== `\t`);
  const tabs = nonCharIndex;
  const indicator = chars
    .slice(tabs === 0 ? undefined : tabs)
    .slice(0, 2)
    .join("");
  const val = chars
    .slice(tabs)
    .slice(2)
    .join("");
  let type: "focus" | "unfocus" | "expanded";
  switch (indicator) {
    case "* ":
      type = "unfocus";
      break;
    case "> ":
      type = "focus";
      break;
    case "  ":
      type = "expanded";
      break;
    default:
      throw new Error(`
Malformed list at line ${idx + 1}
------
I got the following

${s}

But I was expecting a line with some number of tabs followed
by one of the following charaters: '*', '>', ' '.
This character should be followed by a single space before
the actual content of the line.

* This is a valid note
  This is a valid expanded
\t* This is also ok for a note
\t* This is also ok for an expanded section

Try changing the sequence '${indicator}' with one of the above.
`);
  }

  return {
    type,
    tabs,
    val
  };
};
