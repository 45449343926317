import React from "react";
import styles from "../../styles/modal.module.css";
import { Windows } from "../../../state/windows";
import { Bullet as B } from "../../../bulletime-ds";
import * as T from "../../../rosetree/tree";

interface WindowSwitcherProps {
  ws: Windows;
  dispatch: Function;
}

const WindowSwitcher = ({ ws, dispatch }: WindowSwitcherProps) => {
  const windows = [...ws.before, ws.current, ...ws.after];
  const current = ws.before.length;
  return (
    <div className={styles.dialog}>
      <div className={styles.header}>Available Windows:</div>
      <ul>
        {windows.map((w, idx) => (
          <li
            className={idx === current ? styles.current : ""}
            key={B.id(T.label(w.tree))}
            onClick={() =>
              dispatch({ type: "switchToWindow", payload: { idx } })
            }
          >
            {B.note(T.label(w.tree))}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ({ state: s, dispatch }) => (
  <WindowSwitcher ws={s.windows} dispatch={dispatch}></WindowSwitcher>
);
