import { Dropbox } from "dropbox";
import { Backend, BackendResult } from "./backend";

const LS_KEY = "DBX";
const clientId = "qewod6zxct2lskf";

export const isFound = () => window.localStorage.getItem(LS_KEY) !== null;

function getKeyFromPath(): string | undefined {
  if (window.location.pathname.includes("dropbox")) {
    const queryParams = window.location.hash
      .slice(1)
      .split("&")
      .reduce((acc, pair) => {
        const [key, val] = pair.split("=");
        acc[key] = val;
        return acc;
      }, {});

    const accessToken = queryParams["access_token"];

    return accessToken;
  }
}

export async function init(
  defaultData: string
): Promise<BackendResult<Backend>> {
  const dbx = new Dropbox({
    fetch,
    clientId,
  });

  const keyFromPath = getKeyFromPath();
  const keyFromLocalStorage = window.localStorage.getItem(LS_KEY) as string;
  const key = keyFromLocalStorage || keyFromPath;
  if (key) {
    window.localStorage.setItem(LS_KEY, key);
    dbx.setAccessToken(key);
    return ["ok", DropboxBackend(dbx, defaultData)];
  } else {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    window.location.replace(dbx.getAuthenticationUrl(`${baseUrl}/dropbox`));
    return ["ok", DropboxBackend(dbx, defaultData)];
  }
}

function DropboxBackend(dbx: Dropbox, initialData: string): Backend {
  let file: DropboxTypes.files.FileMetadata | undefined = undefined;

  async function save(data: String): Promise<BackendResult<null>> {
    const result = await dbx.filesUpload({
      path: "/data.json",
      contents: data,
      mode: { ".tag": "overwrite" },
      mute: true,
    });
    if (file && file.id !== result.id) {
      return ["err", `Files don't match after write.`];
    }
    return ["ok", null];
  }

  async function load(): Promise<BackendResult<string>> {
    try {
      const result = await dbx.filesSearch({
        path: "",
        query: "data.json",
        mode: { ".tag": "filename" },
        max_results: 1,
      });
      console.dir(result.matches);
      if (result.matches.length < 1) {
        try {
          await save(initialData);
          return await load();
        } catch (e) {
          return ["err", e];
        }
      }
    } catch (e) {
      try {
        await save(initialData);
        return await load();
      } catch (e) {
        return ["err", e];
      }
    }

    try {
      const file = await dbx.filesDownload({ path: "/data.json" });
      const data = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("loadend", () => {
          const result = reader.result as string;
          if (result === "") {
            reject("Did not receive any data");
          }
          resolve(reader.result as string);
        });
        reader.readAsText(file["fileBlob"]);
      });
      return ["ok", data];
    } catch (e) {
      return ["err", e];
    }
  }

  async function signout(): Promise<BackendResult<null>> {
    try {
      file = undefined;
      await dbx.authTokenRevoke();
      return ["ok", null];
    } catch (e) {
      return ["err", e.message];
    }
  }

  return {
    save,
    load,
    signout,
  };
}
