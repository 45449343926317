import { InternalAction } from "../../core/store/action";
import { Dictionary } from "../../utils/core-types";
import { ConfigurationItem } from "./configuration-item";
import { Contribution } from "./contributions";
import {
  ContributionHandler,
  ContributionInputState,
  ContributionOutputState,
} from "./contributions/base";
import {
  ContributionType,
  contributionTypeToKey,
} from "./contributions/contribution-type";
import { ModifierContribution } from "./contributions/modifier";
import { ProviderContribution } from "./contributions/provider";
import { ServiceContribution } from "./contributions/service";
import { ThemeContribution } from "./contributions/theme";
import { ViewContribution, ViewRootComponent } from "./contributions/view";

interface ContributionMap extends Object {
  view?: ViewContribution;
  modifier?: ModifierContribution;
  theme?: ThemeContribution;
  service?: ServiceContribution;
  provider?: ProviderContribution;
}

export interface Plugin {
  name: string;
  description: string;
  version: string;
  contributions: ContributionMap;
  configurationItems: ConfigurationItem[];
}

export function handleAction(
  plugin: Plugin,
  contrib: string,
  action: string,
  state: ContributionInputState,
  payload: any
): ContributionOutputState | [ContributionOutputState, InternalAction] {
  const contribution = plugin.contributions[contrib];
  if (contribution === undefined) {
    return state;
  }
  const handler = contribution.handlers[action] as ContributionHandler;
  if (handler === undefined) {
    return state;
  }
  return handler(state, payload);
}

export function viewContribution(plugin: Plugin): ViewContribution | undefined {
  return contribution<ViewContribution>(plugin, ContributionType.view);
}

export function createViewContribution(
  view: ViewRootComponent,
  handlers: Dictionary<ContributionHandler>
): ViewContribution {
  return {
    type: ContributionType.view,
    handlers: handlers,
    view,
  };
}

function contribution<T extends Contribution>(
  plugin: Plugin,
  type: ContributionType
): T | undefined {
  const contribution = plugin.contributions[contributionTypeToKey(type)];
  return contribution ? (contribution as T) : undefined;
}
