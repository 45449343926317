import { Dictionary } from "../../../../utils/core-types";

export function render(content: string, styles: Dictionary<string>): string {
  return content.search(URL_RE) !== -1
    ? content.replace(
        URL_RE,
        `<a href="$1" target="_blank" rel="noreferer" class="${styles.link}">$1</a>`
      )
    : content
        .replace(/</g, "&#60;")
        .replace(/>/g, "&#62;")
        .replace(
          UNDERLINE_RE,
          `<span class="${styles.underline || ""}">$1<&#47;span>`
        )
        .replace(TAG_RE, `<span class="${styles.tag || ""}">$1<&#47;span>`)
        .replace(
          BOLD_RE,
          `<strong class="${styles.bold || ""}">$1<&#47;strong>`
        )
        .replace(ITALIC_RE, `<em class="${styles.italic || ""}">$1<&#47;em>`)
        .replace(PRE_RE, `<pre class="${styles.pre || ""}">$1<&#47;pre>`)
        .replace(/&#47;/g, "/");
}

const TAG_RE = /(:\S+:)/g;
const BOLD_RE = /(\*\S(.*\S)?\*)/g;
const ITALIC_RE = /(\/\S(.*\S)?\/)/g;
const UNDERLINE_RE = /(_\S(.*\S)?_)/g;
const PRE_RE = /(~\S(.*\S)?~)/g;
const URL_RE = /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)/g;
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function renderDate(date: Date | number) {
  const d = typeof date === "number" ? new Date(date) : date;
  const year = pad4(d.getFullYear());
  const month = pad2(d.getMonth() + 1);
  const day = pad2(d.getDate());
  const dow = days[d.getDay()];
  const hours = pad2(d.getHours());
  const minutes = pad2(d.getMinutes());
  return `<${year}-${month}-${day} ${dow} ${hours}:${minutes}>`;
}

const pad2 = (content: string | number) =>
  (typeof content === "number" ? String(content) : content).padStart(2, "0");
const pad4 = (content: string | number) =>
  (typeof content === "number" ? String(content) : content).padStart(4, "0");
