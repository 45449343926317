import React, { useState, useEffect } from "react";
import { foundBackend, createAppBackend } from "../../services/backend";
import SplashScreen, {
  statusNoUser,
  statusError,
  statusLoading,
  LoadingStatus,
  loadedState,
  loadedBackend,
  loadingStatusIsLoading,
  backendToLoad,
  statusLoaded,
} from "../SplashScreen";
import App from "../App";
import plugins from "../../plugins";
import * as State from "../../../state";

export const Main = () => {
  const maybeBackend = foundBackend();
  const initialStatus: LoadingStatus =
    maybeBackend === undefined ? statusNoUser() : statusLoading(maybeBackend);
  const [status, setStatus] = useState(initialStatus as LoadingStatus);

  useEffect(() => {
    if (loadingStatusIsLoading(status)) {
      const backend = backendToLoad(status);
      const backends = backend === undefined ? [] : [backend];
      createAppBackend(plugins, new Set(backends)).then((result) => {
        if (result[0] === "ok") {
          const backend = result[1];
          backend.load().then((r) => {
            if (r[0] === "ok") {
              setStatus(
                statusLoaded(
                  backend,
                  State.deserialize(plugins, JSON.parse(r[1]))
                )
              );
            } else {
              setStatus(statusNoUser());
            }
          });
        } else {
          setStatus(statusError(result[1]));
        }
      });
    }
  }, [status]);

  if (loadedBackend(status)) {
    return (
      <App
        initialState={loadedState(status)!}
        backend={loadedBackend(status)!}
      />
    );
  } else {
    return <SplashScreen status={status} setStatus={setStatus} />;
  }
};
