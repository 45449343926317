import React from "react";
import dropbox from "../../../dropbox.svg";
import googleDrive from "../../../gdrive.svg";
import logo from "../../../logo.png";
import * as State from "../../../state";
import { Maybe } from "../../../utils/maybe";
import { AppBackend, AvailableBackends } from "../../services/backend";
import styles from "./index.module.css";

interface Props {
  status?: LoadingStatus;
  setStatus: (status: LoadingStatus) => void;
}

export default ({ status = statusUnknown(), setStatus }: Props) => {
  const chooseBackend = (backend?: AvailableBackends): void =>
    setStatus(statusLoading(backend));

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div style={{ display: "flex" }}>
          <img src={logo} className={styles.logo} alt="Bulletime Logo"></img>
          <h1 className={styles.wordmark}>Bulletime</h1>
        </div>
      </div>
      {loadingStatusIsUnknown(status) ? (
        <div className={styles.row}>
          <span className={styles.loading}>It's Bullets all the way down.</span>
        </div>
      ) : loadingStatusIsNoUser(status) || loadingStatusIsError(status) ? (
        <>
          <div className={styles.row}>Welcome. Signin below.</div>
          <div className={styles.row}>
            <button
              className={styles.dropbox}
              onClick={() => {
                chooseBackend(AvailableBackends.dropbox);
              }}
            >
              <img
                src={dropbox}
                style={{ height: "18px", marginTop: "8px" }}
                alt="Save to Dropbox"
              />
            </button>
            <button
              className={styles.googleDrive}
              onClick={() => {
                chooseBackend(AvailableBackends.googleDrive);
              }}
            >
              <img
                src={googleDrive}
                style={{ height: "18px", marginTop: "8px" }}
                alt="Save to Google Drive"
              />
            </button>
          </div>
          <div className={styles.row}>
            <button
              className={styles.getStarted}
              onClick={() => {
                chooseBackend();
              }}
            >
              Just take a look
            </button>
          </div>
        </>
      ) : (
        <div className={styles.row}>
          <span className={styles.loading}>Loading...</span>
        </div>
      )}
      {loadingStatusIsError(status) ? (
        <div className={styles.row}>
          <span className={styles.error}>{(status as StatusError).msg}</span>
        </div>
      ) : null}
    </div>
  );
};

type StatusUnknown = { __status: "UNKNOWN" };
type StatusNoUser = { __status: "NOUSER" };
type StatusLoading = { __status: "LOADING"; backend?: AvailableBackends };
type StatusLoaded = {
  __status: "LOADED";
  backend: AppBackend;
  state: State.State;
};
type StatusError = {
  __status: "ERROR";
  msg: string;
};

export type LoadingStatus =
  | StatusUnknown
  | StatusNoUser
  | StatusLoading
  | StatusLoaded
  | StatusError;

export const statusUnknown = (): StatusUnknown => ({ __status: "UNKNOWN" });
export const statusNoUser = (): StatusNoUser => ({ __status: "NOUSER" });
export const statusLoading = (backend?: AvailableBackends): StatusLoading => ({
  __status: "LOADING",
  backend,
});
export const statusLoaded = (
  backend: AppBackend,
  state: State.State
): StatusLoaded => ({
  __status: "LOADED",
  backend,
  state,
});

export const statusError = (msg: string): StatusError => ({
  __status: "ERROR",
  msg,
});

export const loadingStatusIsUnknown = ({ __status }: LoadingStatus) =>
  __status === "UNKNOWN";
export const loadingStatusIsNoUser = ({ __status }: LoadingStatus) =>
  __status === "NOUSER";
export const loadingStatusIsLoading = ({ __status }: LoadingStatus) =>
  __status === "LOADING";
export const loadingStatusIsLoaded = ({ __status }: LoadingStatus) =>
  __status === "LOADED";
export const loadingStatusIsError = ({ __status }: LoadingStatus) =>
  __status === "ERROR";

export const backendToLoad = (
  loading: LoadingStatus
): Maybe<AvailableBackends> =>
  loadingStatusIsLoading(loading)
    ? (loading as StatusLoading).backend
    : undefined;
export const loadedBackend = (loading): Maybe<AppBackend> =>
  loadingStatusIsLoaded(loading)
    ? (loading as StatusLoaded).backend
    : undefined;
export const loadedState = (loading): Maybe<State.State> =>
  loadingStatusIsLoaded(loading) ? (loading as StatusLoaded).state : undefined;
