import React, { memo, RefObject, useEffect, useRef, useState } from "react";
import { Bullet as B } from "../../../../../../bulletime-ds";
import creators from "../../../actions/creators";
import { MetaKeys } from "../../../actions/MetaKeys";
import { PLUGIN } from "../../../constants";
import { useEditStateFocus } from "../../../hooks/use-edit-state-focus";
import { useEditStateScroll } from "../../../hooks/use-edit-state-scroll";
import { EditingState } from "../../../model/editing-state";
import { FTWNode, nodeDepth, nodeLabel } from "../../../model/flat-tree-window";
import { render } from "../../../utils/render";
import styles from "./styles.module.css";
import compose from "../../../utils/compose-styles";

const { expanded: bexpanded, id, note: bnote, pluginMeta } = B;

export interface BulletProps {
  node: FTWNode<B.Bullet>;
  className?: string;
  hasChildren: boolean;
  bulletEditState: EditingState;
  dispatch: ({ type: string, payload: any }) => void;
}

const collapsedIndicatorStyle = { marginLeft: "36px", color: "#AAAAAA" };

const memoFn = (
  {
    node: [oldDepth, oldBullet],
    hasChildren: oldHasChildren,
    bulletEditState: oldEditState
  },
  { node: [depth, bullet], hasChildren, bulletEditState }
) => {
  return (
    oldDepth === depth &&
    oldBullet === bullet &&
    hasChildren === oldHasChildren &&
    bulletEditState.state !== "note" &&
    (bulletEditState.id !== id(bullet) ||
      oldEditState.state !== bulletEditState.state)
  );
};

const Bullet = memo(
  ({ node, dispatch, hasChildren, bulletEditState }: BulletProps) => {
    const noteEl: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const expandedEl: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
    const [hovered, setHovered] = useState(false);

    const bullet = nodeLabel(node);
    const note = bnote(bullet);
    const depth = Math.max(nodeDepth(node) - 1, 0);
    const collapsed = pluginMeta(PLUGIN, MetaKeys.collapsed, bullet);
    const completed = pluginMeta(PLUGIN, MetaKeys.completed, bullet);
    const expanded = bexpanded(bullet);

    const bumpers = [...Array(depth)].map((_, idx) => (
      <div key={idx} className={styles.bumper}></div>
    ));

    const containerClasses = compose(
      styles.itemContainer,
      hovered ? `${styles.hovered}` : ""
    );
    const expandToggleClasses = compose(
      "material-icons",
      styles.materialIcons,
      ...(hasChildren ? [styles.hasChildren] : [])
    );
    const noteAttrs = {
      className: compose(styles.content, completed ? styles.complete : ""),
      ref: noteEl,
      contentEditable: true,
      id: `bt-note-${id(bullet)}`,
      onBlur: e => {
        if (e.target.innerText !== note) {
          dispatch(creators.noteUpdated(id(bullet), e.target.innerText));
        }
      },
      onClick: () => dispatch(creators.noteSelected(id(bullet))),
      dangerouslySetInnerHTML: { __html: render(note, renderClasses) }
    };
    const expandedAttrs = {
      className: styles.expanded,
      contentEditable: true,
      id: `bt-expanded-${id(bullet)}`,
      ref: expandedEl,
      onBlur: e => {
        if (e.target.innerText !== expanded) {
          dispatch(creators.expandedUpdated(id(bullet), e.target.innerText));
        }
      },
      onClick: () => dispatch(creators.expandedSelected(id(bullet))),
      dangerouslySetInnerHTML: {
        __html: render(expanded || "", renderClasses)
      }
    };

    const bulletHandlers = {
      onMouseOver: () => setHovered(true),
      onMouseLeave: () => setHovered(false),
      onClick: () => dispatch({ type: "focusOn", payload: id(bullet) })
    };

    useEditStateScroll(bulletEditState, id(bullet), noteEl, expandedEl, [
      bulletEditState.state
    ]);
    useEditStateFocus(bulletEditState, id(bullet), noteEl, expandedEl, [
      bulletEditState,
      depth
    ]);
    useDeleteHandler(noteEl, expandedEl);

    return (
      <div className={containerClasses}>
        {bumpers}
        <div style={{ flexGrow: 1 }}>
          <div className={styles.contentContainer}>
            <div
              className={styles.expandToggle}
              onClick={() => dispatch(creators.toggleCollapsed(id(bullet)))}
            >
              <i className={expandToggleClasses}>
                {collapsed ? "keyboard_arrow_right" : "keyboard_arrow_down"}
              </i>
            </div>
            <div className={styles.bullet} {...bulletHandlers}>
              <DefaultBulletIcon />
            </div>
            <div {...noteAttrs}></div>
          </div>
          {expanded !== undefined ? <div {...expandedAttrs}></div> : null}
          {collapsed ? <div style={collapsedIndicatorStyle}>...</div> : null}
        </div>
      </div>
    );
  },
  memoFn
);

function DefaultBulletIcon() {
  return (
    <i className="material-icons" style={{ fontSize: "10px" }}>
      lens
    </i>
  );
}

function useDeleteHandler(
  noteEl: RefObject<HTMLDivElement>,
  expandedEl: RefObject<HTMLDivElement>
) {
  useEffect(() => {
    function deleteHandler(this: HTMLDivElement, e: KeyboardEvent) {
      if (e.key !== "Backspace") {
        return;
      }
      const s = window.getSelection();
      if (s !== null && !s.isCollapsed) {
        console.log(s.toString());
        s.deleteFromDocument();
        e.preventDefault();
      }
    }
    if (noteEl.current !== null) {
      noteEl.current.addEventListener(
        "keydown",
        deleteHandler.bind(noteEl.current)
      );
    }
    if (expandedEl.current !== null) {
      expandedEl.current.addEventListener(
        "keydown",
        deleteHandler.bind(expandedEl.current)
      );
    }
  }, [noteEl, expandedEl]);
}

const renderClasses = {
  tag: styles.tag,
  link: styles.link,
  bold: styles.bold,
  italic: styles.italic,
  underline: styles.underline,
  pre: styles.pre
};

export { Bullet };
