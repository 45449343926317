// FlatTreeWindow
// by Anthony Raymond Bullard
//
// A useful utility that can mount a tree into a zipper
// and produce a flattened window of a specified size of
// the label type of the tree
import { count, Tree } from "../../../../rosetree/tree";
import {
  backward,
  depth,
  forward,
  fromTree as zFromTree,
  label,
  root,
  Zipper
} from "../../../../rosetree/zipper";

enum Movement {
  forward,
  backward
}

export interface FTW<T> {
  readonly zipper: Zipper<T>; // The cursor
  readonly windowSize?: number; // How big of a window
  readonly numStepsDown: number; // The number of steps down we've went
  readonly height: number; // The total size of the tree
  readonly curr?: Array<[number, T]>;
}

export type FTWNode<T> = [number, T];

export const nodeDepth = <T>([depth, _]: FTWNode<T>): number => depth;
export const nodeLabel = <T>([_, label]: FTWNode<T>): T => label;

export const fromTree = <T>(tree: Tree<T>, windowSize?: number): FTW<T> => ({
  zipper: zFromTree<T>(tree),
  windowSize,
  numStepsDown: 0,
  height: count(tree)
});

export const window = <T>(ftw: FTW<T>): [Array<FTWNode<T>>, FTW<T>] => {
  if (ftw.curr !== undefined) {
    return [ftw.curr, ftw];
  } else {
    // Calculate window, set it to curr, and return
    const windowSize = ftw.windowSize
      ? Math.min(ftw.height - ftw.numStepsDown, ftw.windowSize)
      : ftw.height;
    const [window] = zeroRange(windowSize).reduce(collectLabels, [
      [],
      ftw.windowSize ? ftw.zipper : root(ftw.zipper)
    ]);
    return [
      window,
      {
        zipper: ftw.zipper,
        windowSize: ftw.windowSize,
        numStepsDown: ftw.numStepsDown,
        height: ftw.height,
        curr: window
      }
    ];
  }
};

export const withWindowSize = <T>(
  windowSize: number,
  window: FTW<T>
): FTW<T> => ({
  ...window,
  windowSize,
  curr: undefined
});

export const withTree = <T>(tree: Tree<T>, window: FTW<T>): FTW<T> => {
  const height = count(tree);
  const steps = window.numStepsDown + (height - window.height);
  const zipper = zeroRange(Math.min(steps, height)).reduce(
    (acc: Zipper<T>, _): Zipper<T> => forward(acc) || acc,
    zFromTree(tree)
  );

  return {
    ...window,
    zipper,
    height,
    curr: undefined
  };
};

export const moveForward = <T>(ftw: FTW<T>, numMoves: number): FTW<T> =>
  move(ftw, numMoves, Movement.forward);

export const moveBackward = <T>(ftw: FTW<T>, numMoves: number): FTW<T> =>
  move(ftw, numMoves, Movement.backward);

// Internal functions

// Creates an unititialized array of `len` elements for iteration
const zeroRange = (len: number) => [...Array(len)];

const move = <T>(
  { zipper, windowSize, numStepsDown, height }: FTW<T>,
  numMoves: number,
  movement: Movement
): FTW<T> => {
  const moveFn: (Z: Zipper<T>) => Zipper<T> | undefined =
    movement === Movement.forward ? forward : backward;

  const newZipper = zeroRange(numMoves).reduce((acc: Zipper<T>, _: any): Zipper<
    T
  > => {
    return moveFn(acc) || acc;
  }, zipper);

  return {
    zipper: newZipper,
    windowSize,
    height,
    numStepsDown: Math.max(
      movement === Movement.forward ? numStepsDown + 1 : numStepsDown - 1,
      0
    ),
    curr: undefined
  };
};

const collectLabels = <T>(
  [window, zip]: [Array<FTWNode<T>>, Zipper<T> | undefined],
  _: any
): [Array<FTWNode<T>>, Zipper<T> | undefined] => {
  if (zip === undefined) {
    return [window, zip];
  }
  const newZip: Zipper<T> | undefined = forward(zip);
  return [[...window, [depth(zip), label(zip)]], newZip];
};
