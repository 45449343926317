import { Bullet as B } from "../bulletime-ds";
import { Tree } from "../rosetree/tree";
import { Plugin } from "./plugin";
import { ViewContribution } from "./plugin/contributions/view";

export interface Window {
  readonly tree: Tree<B.Bullet>;
  readonly plugin: string;
  readonly view: ViewContribution;
}

export function init(tree: Tree<B.Bullet>, plugin: Plugin): Window {
  return {
    tree,
    view: plugin.contributions.view!,
    plugin: plugin.name
  };
}

export function serialize(w: Window, plugins: Plugin[]): Object {
  return {
    tree: w.tree,
    plugin: w.plugin
  };
}

export function deserialize(w: Object, plugins: Plugin[]): Window {
  const plugin = plugins.find(p => p.name === w["view"]) || plugins[0];
  return {
    tree: w["tree"],
    view: plugin.contributions.view!,
    plugin: plugin.name
  };
}
