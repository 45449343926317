// @ts-check

import React from "react";
import ReactDOM from "react-dom";
import { Main, SplashScreen } from "./core";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

// We want to immediately load the SplashScreen
ReactDOM.render(
  <SplashScreen setStatus={() => {}} />,
  document.getElementById("root")
);

ReactDOM.render(<Main />, document.getElementById("root"));
serviceWorker.unregister();
