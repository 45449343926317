import { Tree, singleton, label } from "../../../../rosetree/tree";
import { useMemo } from "react";
import { PLUGIN } from "../constants";
import { MetaKeys } from "../actions/MetaKeys";
import * as Z from "../../../../rosetree/zipper";
import { Bullet as B } from "../../../../bulletime-ds";
import * as BT from "../../../../bulletime-ds/bullet-tree";

export default (tree: Tree<B.Bullet>) => {
  const mapped = useMemo(() => {
    let zip = Z.fromTree(tree);
    let maybeZip: BT.BulletTree | undefined = undefined;
    while (true) {
      let result = Z.findNext(
        b => B.pluginMeta(PLUGIN, MetaKeys.collapsed, b),
        maybeZip || zip
      );
      if (result === undefined) {
        break;
      }
      maybeZip = Z.mapTree(t => {
        return singleton(label(t));
      }, result);
    }
    return maybeZip || zip;
  }, [tree]);

  return mapped;
};
