import * as State from "../../state";
import { Plugin } from "../../state/plugin";
import { Maybe } from "../../utils/maybe";
import { init as dbInit, isFound as dbIsFound } from "./dropbox";
import { init as gdInit, isFound as gdIsFound } from "./google-drive";
import { init as lsInit } from "./local-storage";

export type BackendResult<T> = ["ok", T] | ["err", string];

export interface Backend {
  save: (data: string) => Promise<BackendResult<null>>;
  load: () => Promise<BackendResult<string>>;
  signout: () => Promise<BackendResult<null>>;
}

export interface AppBackend extends Backend {
  addBackend: (
    backend: AvailableBackends,
    currentState: string
  ) => Promise<BackendResult<null>>;
}

export enum AvailableBackends {
  googleDrive,
  dropbox
}

const backendName = (backend: AvailableBackends) => {
  switch (backend) {
    case AvailableBackends.dropbox:
      return "Dropbox";
    case AvailableBackends.googleDrive:
      return "Google Drive";
    default:
      return "Unknown";
  }
};

const backendFactories: Map<
  AvailableBackends,
  (state: string) => Promise<BackendResult<Backend>>
> = (() => {
  const map = new Map();
  map.set(AvailableBackends.googleDrive, gdInit);
  map.set(AvailableBackends.dropbox, dbInit);
  return map;
})();

export const createAppBackend = async (
  plugins: Plugin[],
  withBackends: Set<AvailableBackends>
): Promise<BackendResult<AppBackend>> => {
  const lsResult = await lsInit(State.init(plugins, plugins[0].name));
  if (lsResult[0] === "err") {
    throw new Error("Should never happen");
  }
  const localStorageBackend = lsResult[1];
  const lsDataResult = await localStorageBackend.load();
  const initialRawState = lsDataResult[0] === "err" ? null : lsDataResult[1];
  const state =
    initialRawState === null
      ? State.serialize(State.init(plugins, plugins[0].name))
      : initialRawState;

  let backends: Map<AvailableBackends, Backend> = new Map();

  const promises: Promise<
    [AvailableBackends, BackendResult<Backend>]
  >[] = Array.from(withBackends.values()).map(b => {
    const factory = backendFactories.get(b)!;
    return factory(state).then(r => [b, r]);
  });

  const results = await Promise.all(promises);

  for (const [backend, result] of results) {
    if (result[0] === "err") {
      return ["err", `Could not connect to ${backendName(backend)}`];
    } else {
      backends.set(backend, result[1]);
    }
  }

  if (backends.size === 0) {
    localStorageBackend.save(state);
  }

  async function load(): Promise<BackendResult<string>> {
    if (backends.size === 0) {
      return await localStorageBackend.load();
    }
    const data: [AvailableBackends, BackendResult<string>] = await Promise.race(
      Array.from(backends.entries()).map(
        async ([provider, backend]): Promise<
          [AvailableBackends, BackendResult<string>]
        > => {
          const data = await backend.load();
          return [provider, data];
        }
      )
    );

    if (data[1][0] === "err") {
      return await localStorageBackend.load();
    }

    return data[1][1] === "" ? ["ok", initialRawState!] : data[1];
  }

  async function save(state: string): Promise<BackendResult<null>> {
    await localStorageBackend.save(state);
    const data: Array<[
      AvailableBackends,
      BackendResult<null>
    ]> = await Promise.all(
      Array.from(backends.entries()).map(
        async ([provider, backend]): Promise<
          [AvailableBackends, BackendResult<null>]
        > => {
          const data = await backend.save(state);
          return [provider, data];
        }
      )
    );

    const failed = data.filter(r => r[1][0] === "err");
    console.log(
      "Saved to the following backends...\n" +
        data.filter(r => r[1][0] === "ok").map(r => `${backendName(r[0])}`)
    );
    if (failed.length > 0) {
      return [
        "err",
        `Failed to save to ${failed.map(f => backendName(f[0])).join(", and ")}`
      ];
    }

    return ["ok", null];
  }

  async function signout(): Promise<BackendResult<null>> {
    const data: Array<[
      AvailableBackends,
      BackendResult<null>
    ]> = await Promise.all(
      Array.from(backends.entries()).map(
        async ([provider, backend]): Promise<
          [AvailableBackends, BackendResult<null>]
        > => {
          const data = await backend.signout();
          return [provider, data];
        }
      )
    );

    const failed = data.filter(r => r[1][0] === "err");
    if (failed.length > 0) {
      return [
        "err",
        `Failed to sign out of ${failed
          .map(f => backendName(f[0]))
          .join(", and ")}`
      ];
    }

    return ["ok", null];
  }
  async function addBackend(
    backend: AvailableBackends,
    currentState: string
  ): Promise<BackendResult<null>> {
    if (backends.get(backend) !== undefined) {
      return await Promise.resolve(["ok", null]);
    }

    const factory = backendFactories.get(backend)!;
    const result = await factory(state);
    if (result[0] === "err") {
      return result;
    }

    backends.set(backend, result[1]);

    return ["ok", null];
  }

  const appBackend = {
    load,
    save,
    signout,
    addBackend
  };

  return ["ok", appBackend];
};

export const foundBackend = (): Maybe<AvailableBackends> => {
  if (dbIsFound()) {
    return AvailableBackends.dropbox;
  } else if (gdIsFound()) {
    return AvailableBackends.googleDrive;
  } else {
    return undefined;
  }
};
