// rosetree
import * as T from "../../../../rosetree/tree";
import * as Z from "../../../../rosetree/zipper";

// bulletime-ds
import { Bullet as B, BulletTree as BT } from "../../../../bulletime-ds/";

// bulletime-state
import {
  ContributionHandler,
  ContributionInputState,
  ContributionOutputState,
} from "../../../../state/plugin/contributions/base";

// bulletime-utils
import { partial } from "../../../../utils/function";
import { Maybe, pipe } from "../../../../utils/maybe";
import { Dictionary } from "../../../../utils/core-types";

// bulletime-plugin-bulletime-core
import { PLUGIN } from "../constants";
import { EditingState } from "../model/editing-state";
import { BulletimeCoreState, defaultState } from "../model/state";
import Constants from "./constants";
import creators from "./creators";
import { MetaKeys } from "./MetaKeys";

const stateForTree = (state: ContributionInputState): BulletimeCoreState => {
  return (state.storage[B.id(T.label(state.tree))] ||
    defaultState) as BulletimeCoreState;
};

// const LINK_RE = /\[\[(.*)\]\[(.*)\]\]/g;

function updateSelectedBullet(
  state: ContributionInputState,
  fn: (tree: BT.BulletTree) => Maybe<BT.BulletTree>
): ContributionOutputState {
  const s = stateForTree(state);
  const id = s.editState.state === "note" ? s.editState.id : undefined;
  return id === undefined ? state : updateBulletAtId(id, state, fn);
}

function updateBulletAtId(
  id: String,
  state: ContributionInputState,
  fn: (tree: BT.BulletTree) => Maybe<BT.BulletTree>
): ContributionOutputState {
  const zip = BT.fromTree(state.tree);
  const updated = pipe(zip, [partial(BT.to, id), fn]);

  return updated ? updateCurrentWindowTree(Z.toTree(updated), state) : state;
}

function nav(
  state: ContributionInputState,
  moveFn: (tree: BT.BulletTree) => Maybe<BT.BulletTree>
): ContributionOutputState {
  const tree = state.tree;
  const viewState = stateForTree(state);
  if (viewState.editState.state !== "note") {
    return state;
  }
  const id = viewState.editState.id;
  const bt = BT.fromTree(tree);
  const atNew = pipe(bt, [partial(BT.to, id), moveFn]) || bt;

  const newViewState = {
    ...viewState,
    editState: {
      state: "note",
      id: B.id(BT.current(atNew)),
    },
  };

  return updateCurrentWindowState(newViewState, state);
}

function updateCurrentWindowState(
  newWindowState: any,
  state: ContributionInputState | ContributionOutputState
): ContributionOutputState {
  return {
    ...state,
    storage: {
      ...state.storage,
      [B.id(T.label(state.tree))]: newWindowState,
    },
  };
}

function updateCurrentWindowTree(
  newTree: any,
  state: ContributionInputState | ContributionOutputState
): ContributionOutputState {
  return {
    ...state,
    tree: newTree,
  };
}

function editStateFromState(state: ContributionInputState): EditingState {
  const { editState } = stateForTree(state);
  return editState;
}

const handlers: Dictionary<ContributionHandler> = {
  [Constants.navDown]: (state) => {
    const tree = state.tree;
    const editState = editStateFromState(state);
    if (editState.state === "none") {
      return state;
    }
    const selected = BT.to(editState.id, BT.fromTree(tree));
    if (selected === undefined) {
      return state;
    }
    const isBottom =
      B.id(BT.current(selected)) === B.id(BT.current(BT.bottom(selected)));
    if (isBottom) {
      return state;
    }
    const isCollapsed = B.pluginMeta(
      PLUGIN,
      MetaKeys.collapsed,
      BT.current(selected)
    );
    if (isCollapsed) {
      return nav(state, BT.down);
    }
    return nav(state, BT.forward);
  },

  [Constants.navUp]: (state) => {
    const tree = state.tree;
    const editState = editStateFromState(state);
    if (editState.state === "none") {
      return state;
    }
    const selected = BT.to(editState.id, BT.fromTree(tree));
    const prevSibling = Z.previousSibling(selected);
    const parent = Z.parent(selected);
    if (
      prevSibling === undefined &&
      parent !== undefined &&
      B.id(BT.current(parent)) === B.id(T.label(tree))
    ) {
      return state;
    }
    if (prevSibling === undefined) {
      return nav(state, BT.backward);
    }
    const prevIsCollapsed = B.pluginMeta(
      PLUGIN,
      MetaKeys.collapsed,
      BT.current(prevSibling)
    );
    if (prevIsCollapsed) {
      return nav(state, BT.up);
    }
    return nav(state, BT.backward);
  },

  [Constants.selectedNote]: (state, payload: string) => {
    const viewState = stateForTree(state);
    const newViewState = {
      ...viewState,
      editState: {
        state: "note",
        id: payload,
      },
    };

    return updateCurrentWindowState(newViewState, state);
  },
  [Constants.selectedExpanded]: (state, payload: { id: string }) => {
    const zip = BT.fromTree(state.tree);
    const atId = BT.to(payload.id, zip);
    const updated =
      B.expanded(BT.current(atId)) === undefined
        ? BT.updateExpanded("", atId)
        : atId;
    const newTree = Z.toTree(updated);
    console.log("expanded", B.expanded(BT.current(updated)));
    const newState: BulletimeCoreState = {
      ...stateForTree(state),
      editState: {
        state: "expanded",
        id: payload.id,
      },
    };

    return updateCurrentWindowState(
      newState,
      updateCurrentWindowTree(newTree, state)
    );
  },
  [Constants.toggleNoteExpanded]: (state) => {
    const viewState = stateForTree(state);
    if (viewState.editState.state === "none") {
      return state;
    }

    // const withExpanded =
    //   viewState.editState.state === "note"
    //     ? updateSelectedBullet(state, partial(BT.createExpanded))
    //     : state;

    const newViewState = {
      ...viewState,
      editState: {
        state: viewState.editState.state === "note" ? "expanded" : "note",
        id: viewState.editState.id,
      },
    };

    return updateCurrentWindowState(newViewState, state);
  },
  [Constants.toggleCollapsed]: (state, payload: Maybe<string>) => {
    const fn = partial(Z.mapLabel, (b: B.Bullet) =>
      B.mapPluginMeta((v: boolean) => !v, PLUGIN, MetaKeys.collapsed, b)
    );
    return payload === undefined
      ? updateSelectedBullet(state, fn)
      : updateBulletAtId(payload, state, fn);
  },
  [Constants.toggleCompleted]: (state) =>
    updateSelectedBullet(
      state,
      partial(Z.mapLabel, (b: B.Bullet) =>
        B.mapPluginMeta((v: boolean) => !v, PLUGIN, MetaKeys.completed, b)
      )
    ),
  [Constants.noteUpdated]: (state, payload: { id: string; note: string }) =>
    updateBulletAtId(payload.id, state, partial(BT.updateNote, payload.note)),
  [Constants.expandedUpdated]: (
    state,
    payload: { id: string; expanded: string }
  ) =>
    updateBulletAtId(
      payload.id,
      state,
      partial(
        BT.updateExpanded,
        payload.expanded === "" ? undefined : payload.expanded
      )
    ),
  [Constants.indent]: (state) => updateSelectedBullet(state, BT.indent),
  [Constants.dedent]: (state) => updateSelectedBullet(state, BT.dedent),
  [Constants.moveUp]: (state) => updateSelectedBullet(state, BT.moveUp),
  [Constants.moveDown]: (state) => updateSelectedBullet(state, BT.moveDown),
  [Constants.focusOn]: (state) => {
    const s = stateForTree(state);
    const id =
      s.editState.state === "note" || s.editState.state === "expanded"
        ? s.editState.id
        : undefined;
    return [state, { type: "focusOn", payload: id }];
  },
  [Constants.addNote]: (state) => {
    const editState = editStateFromState(state) || { state: "none" };
    if (editState.state !== "note") {
      return state;
    }
    return [updateSelectedBullet(state, BT.addSibling), creators.navDown()];
  },
  [Constants.addExpanded]: (state) =>
    editStateFromState(state).state === "note"
      ? [
          updateSelectedBullet(state, partial(BT.updateExpanded, "")),
          creators.toggleNoteExpanded(),
        ]
      : state,
  [Constants.deleteNote]: (state) => {
    const editState = editStateFromState(state);
    if (editState.state !== "note") {
      return state;
    }
    const updated = updateSelectedBullet(state, BT.remove);
    const atOldBullet = BT.to(editState.id, BT.fromTree(state.tree));
    const atUp = BT.up(atOldBullet);
    const atNewSelected = atUp !== undefined ? atUp : BT.backward(atOldBullet);
    if (atNewSelected === undefined) {
      // This is an edge case, if we can go neither up nor backward, we are deleting the root.
      // Which is bad.
      return state;
    }
    const newWindowState = {
      ...state,
    };

    return updateCurrentWindowState(newWindowState, updated);
  },
  [Constants.addExpandedAtRoot]: (state) => {
    const rootId = B.id(T.label(state.tree));
    const updated = updateBulletAtId(
      rootId,
      state,
      partial(BT.updateExpanded, "")
    );
    const editState = {
      id: rootId,
      state: "expanded",
    };

    return updateCurrentWindowState(
      {
        ...stateForTree(state),
        editState,
      },
      updated
    );
  },
  [Constants.toggleBookmark]: (state) => {
    const id = B.id(T.label(state.tree));
    return (state.storage.BOOKMARKS || []).includes(id)
      ? {
          ...state,
          storage: {
            ...state.storage,
            BOOKMARKS: state.storage.BOOKMARKS.filter((bm) => bm !== id),
          },
        }
      : {
          ...state,
          storage: {
            ...state.storage,
            BOOKMARKS: [...(state.storage.BOOKMARKS || []), id],
          },
        };
  },
};

export default handlers;
