// rosetree
import * as T from "../../rosetree/tree";
import * as Z from "../../rosetree/zipper";

// bulletime-ds
import { Bullet as B, BulletTree as BT } from "../../bulletime-ds";

// bulletime-core
import bookmarksModal from "../components/BookmarksModal";
import windowSwitcher from "../components/WindowSwitcher";

// bulletime-state
import { newWindow as sNewWindow, State } from "../../state";
import * as S from "../../state/storage";
import * as W from "../../state/windows";
import * as C from "../../state/config";

// bulletime-utils
import { partial } from "../../utils/function";
import { pipe } from "../../utils/maybe";
import { Serializable } from "../../utils/core-types";

// core
import Constants from "../../core/constants";

export const focusOn = (state: State, _payload: any): State => {
  const id = _payload as string;
  const newCanonical = pipe(BT.fromTree(state.canonical), [
    partial(BT.to, B.id(T.label(state.windows.current.tree))),
    partial(Z.replaceTree, state.windows.current.tree),
  ]);

  if (newCanonical === undefined) {
    // TODO(gamebox): More robust error handling here please
    throw new Error("THIS IS A PROBLEM!");
  }
  const newTree = Z.tree(BT.to(id, newCanonical));
  /* With proposed StateWrapper api
  return state.unmountCurrentWindowTree()
              .setCurrentWindowTreeToId(id)
  */

  return {
    ...state,
    canonical: Z.toTree(newCanonical),
    windows: {
      ...state.windows,
      current: {
        ...state.windows.current,
        tree: newTree,
      },
    },
  };
};

/* With proposed StateWrapper api
  return state.unmountCurrentWindowTree()
              .setCurrentWindowTreeToParent()
  */
export const focusOut = (state: State, _payload: any): State => {
  const id = B.id(T.label(state.windows.current.tree));
  const zip = Z.parent(BT.to(id, BT.fromTree(state.canonical)));
  if (zip === undefined) {
    return state;
  }
  const parentId = B.id(BT.current(zip));
  return focusOn(state, parentId);
};

/* With proposed StateWrapper api
  return state.unmountCurrentWindowTree()
              .setCurrentWindowTreeToRoot(id)
  */
export const focusHome = (state: State, _payload: any): State => {
  const id = B.id(T.label(state.canonical));
  return focusOn(state, id);
};

/* With proposed StateWrapper api
  return state.openNewWindow()
  */
export const newWindow = (state: State, payload: { id: string }): State => {
  return sNewWindow(state);
};

/* With proposed StateWrapper api
  return state.switchToNextWindow()
  */
export const nextWindow = (state: State, payload): State => ({
  ...state,
  windows: W.nextWindow(state.windows),
});

/* With proposed StateWrapper api
  return state.switchToPreviousWindow()
  */
export const prevWindow = (state: State, payload): State => ({
  ...state,
  windows: W.previousWindow(state.windows),
});

export const switchToWindow = (
  state: State,
  payload: { idx: number }
): State => ({
  ...state,
  windows: W.windowAtIndex(payload.idx, state.windows),
});

/* With proposed StateWrapper api
  return state.closeCurrentWindow()
  */
export const closeWindow = (state: State, payload): State => ({
  ...state,
  windows: W.closeWindow(state.windows) || state.windows,
});

/* With proposed StateWrapper api
  return state.mountModal(modal)
  */
export const showWindowSwitcher = (state: State, payload): State => ({
  ...state,
  modal: windowSwitcher,
});

/* With proposed StateWrapper api
  return state.mountModal(modal)
  */
export const showBookmarksModal = (state: State, payload): State => ({
  ...state,
  modal: bookmarksModal,
});

/* With proposed StateWrapper api
  return state.unmountModal(modal)
  */
export const hideModal = (state: State, payload): State => {
  return {
    ...state,
    modal: undefined,
  };
};

/* With proposed StateWrapper api
  return state.toggleBookmarkForCurrentWindowTree()
  */
export const toggleBookmark = (state: State, payload): State => {
  const id = B.id(T.label(state.windows.current.tree));
  return {
    ...state,
    storage: S.toggleBookmark(id, state.storage),
  };
};
// export const showBookmarks = (state: State, payload): State => {};

const setRootProp = (propName: string, val: string): void => {
  document.documentElement.style.setProperty(propName, val);
};

const setBackgroundColor = (state: State, { color }): State => {
  setRootProp("--uiBackgroundColor", color);
  return state;
};

const setForegroundColor = (state: State, { color }): State => {
  setRootProp("--uiPrimaryColor", color);
  return state;
};

const setSecondaryColor = (state: State, { color }): State => {
  setRootProp("--uiSecondaryColor", color);
  return state;
};

const setFont = (state: State, { font }): State => {
  setRootProp("--uiFont", font);
  return state;
};

const updateConfig = (
  state: State,
  { key, val }: { key: string; val: Serializable }
): State => {
  const newConf = C.update(key, val, state.config);
  console.dir(newConf);
  return {
    ...state,
    config: newConf,
  };
};

const toggleConfigPanel = (state: State): State => {
  const isOpen = state.cache[Constants.configPanelOpenCacheKey] || false;
  return {
    ...state,
    cache: {
      ...state.cache,
      [Constants.configPanelOpenCacheKey]: !isOpen,
    },
  };
};

export default [
  { type: "focusOut", fn: focusOut },
  { type: "focusOn", fn: focusOn },
  { type: "focusHome", fn: focusHome },
  { type: "newWindow", fn: newWindow },
  { type: "nextWindow", fn: nextWindow },
  { type: "prevWindow", fn: prevWindow },
  { type: "switchToWindow", fn: switchToWindow },
  { type: "closeWindow", fn: closeWindow },
  { type: "showWindowSwitcher", fn: showWindowSwitcher },
  { type: "hideModal", fn: hideModal },
  { type: "showBookmarksModal", fn: showBookmarksModal },
  { type: "setBackgroundColor", fn: setBackgroundColor },
  { type: "setForegroundColor", fn: setForegroundColor },
  { type: "setSecondaryColor", fn: setSecondaryColor },
  { type: "updateConfig", fn: updateConfig },
  { type: "setFont", fn: setFont },
  { type: "toggleRootBookmark", fn: toggleBookmark },
  { type: "toggleConfigPanel", fn: toggleConfigPanel },
];
