import { useState } from "react";

export function useToTopIndicator(threshold: number): boolean {
  const [current, setCurrent] = useState(window.scrollY > threshold);
  window.addEventListener("scroll", e => {
    if (current) {
      if (window.scrollY < threshold) {
        setCurrent(false);
      }
    } else {
      if (window.scrollY >= threshold) {
        setCurrent(true);
      }
    }
  });

  return current;
}
