const navDown = "navDown";
const navUp = "navUp";
const selected = "selected";
const toggleCollapsed = "toggleCollapsed";
const toggleCompleted = "toggleCompleted";
const selectedNote = "selectedNote";
const selectedExpanded = "selectedExpanded";
const toggleNoteExpanded = "toggleNoteExpanded";
const noteUpdated = "noteUpdated";
const expandedUpdated = "expandedUpdated";
const indent = "indent";
const dedent = "dedent";
const moveUp = "moveUp";
const moveDown = "moveDown";
const showBookmarksModal = "showBookmarksModal";
const focusOn = "focusOn";
const addNote = "addNote";
const addExpanded = "addExpanded";
const deleteNote = "deleteNote";
const addExpandedAtRoot = "addExpandedAtRoot";
const toggleBookmark = "toggleBookmark";

export default {
  navDown,
  navUp,
  selected,
  toggleCollapsed,
  toggleCompleted,
  selectedNote,
  selectedExpanded,
  toggleNoteExpanded,
  noteUpdated,
  expandedUpdated,
  indent,
  dedent,
  moveUp,
  moveDown,
  showBookmarksModal,
  focusOn,
  addNote,
  addExpanded,
  deleteNote,
  addExpandedAtRoot,
  toggleBookmark,
};
