import { createViewContribution } from "../../../../state/plugin";
import mappings from "../actions/handlers";
import BulletimeCore from "./components/BulletimeCore";

export default createViewContribution(
  BulletimeCore,
  // (tree): BulletimeCoreState => ({
  //   editState: editingNote(B.id(T.label(tree))),
  //   rootIsBookmarked: true
  // }),
  // (tree: T.Tree<B.Bullet>, state: State): BulletimeCoreState => ({
  //   editState: { state: "none" },
  //   rootIsBookmarked: isBulletBookmarked(B.id(T.label(tree)), state.storage)
  // }), // treeDidChange
  mappings
);
