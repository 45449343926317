import React, { Reducer, useEffect, useReducer, useRef } from "react";
import { id } from "../../../bulletime-ds/bullet";
import { createKeyHandlerMap, useKeyHandler } from "../../../keyboard";
import { keyMappings } from "../../plugins/bulletime-core";
import { State, currentViewState } from "../../../state";
import * as Config from "../../../state/config";
import { ConfigurationItem } from "../../../state/plugin/configuration-item";
import { count } from "../../../state/windows";
import store from "../../store";
import { InternalAction } from "../../store/action";
import { label } from "../../../rosetree/tree";
import { useStateSaver } from "../../hooks/use-state-saver";
import coreMappings from "../../keyboard-map";
import { AppBackend } from "../../services/backend";
import Breadcrumbs from "../Breadcrumbs";
import ConfigPanel from "../ConfigPanel";
import SaveStatus from "../SaveStatus";
import pluginsList from "../../plugins";
import "./App.css";
import configItemMap from "../../config";

interface AppProps {
  initialState: State;
  backend: AppBackend;
}

// interface AppPropsProposal {
//   numWindows: number;
//   Modal: Maybe<React.FunctionComponent<{}>>;
//   breadcrumbs: {
//     title: string;
//     id: string;
//   }[];
// }

function App({ initialState, backend }: AppProps) {
  const keyMap = useRef(createKeyHandlerMap([...keyMappings, ...coreMappings]));
  const reducer = store(pluginsList);
  const [state, rawDispatch] = useReducer<Reducer<State, InternalAction>>(
    reducer,
    initialState
  );

  const configPanelOpen = state.cache["CORE.CONFIG_PANEL_OPEN"] || false;

  const dispatch = (arg) => {
    return rawDispatch(arg);
  };

  const [lastSaveInMS, isDirty] = useStateSaver(state, backend);
  useKeyHandler(keyMap.current, dispatch, state);

  const configItems: ConfigurationItem[] = Array.from(
    configItemMap().values()
  ).reduce((acc, vs) => [...acc, ...vs], []);
  useEffect(() => {
    const actionsToDispatch = configItems.reduce<InternalAction[]>(
      (acc, item): InternalAction[] => {
        const val = Config.get(item.key, state.config);

        if (val !== undefined) {
          switch (item.kind) {
            case "string":
              return [...acc, item.action(val as string)];
            case "stringOpts":
              return [...acc, item.action(val as string)];
            case "number":
              return [...acc, item.action(val as number)];
            case "boolean":
              return [...acc, item.action(val as boolean)];
          }
        }
        // else, don't
        return acc;
      },
      []
    );

    // Call dispatch
    actionsToDispatch.forEach((action) => {
      dispatch(action);
    });
  }, [configItems, state.config]);

  const { modal: Modal, windows } = state;
  const windowCount = count(state.windows);
  if (!windows || windowCount === 0) {
    return <div />;
  }
  const PluginView = windows.current.view.view;
  const pluginState = currentViewState(state);

  const windowIconText = `filter_${
    windowCount <= 9 ? "" + windowCount : "9_plus"
  }`;

  return (
    <div className="App-container header-right">
      {Modal !== undefined ? (
        <div
          className="modal"
          onClick={() => dispatch({ type: "hideModal", payload: undefined })}
        >
          <Modal state={state} dispatch={dispatch}></Modal>
        </div>
      ) : null}
      <div className="header">
        <i
          className="material-icons"
          onClick={() =>
            dispatch({ type: "showWindowSwitcher", payload: null })
          }
        >
          {windowIconText}
        </i>
        <i
          className="material-icons"
          onClick={() =>
            dispatch({ type: "showBookmarksModal", payload: null })
          }
        >
          bookmark
        </i>
        <i
          className="material-icons"
          onClick={() =>
            dispatch({ type: "focusOn", payload: id(label(state.canonical)) })
          }
        >
          home
        </i>
        <Breadcrumbs
          tree={state.canonical}
          current={windows.current.tree}
          onCrumbClicked={(id_: string) =>
            dispatch({ type: "focusOn", payload: id_ })
          }
        ></Breadcrumbs>
        <div className="sync-status-container">
          <SaveStatus time={lastSaveInMS} isDirty={isDirty}></SaveStatus>
        </div>
      </div>
      <div className="App">
        <PluginView state={pluginState} dispatch={dispatch}></PluginView>
        {configPanelOpen ? (
          <ConfigPanel
            config={state.config}
            itemMap={configItemMap()}
            dispatch={dispatch}
          />
        ) : null}
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        ></link>
      </div>
    </div>
  );
}

export default App;

// function useAppBoostrap(initialState: State, backend: AppBackend) {
//   const keyMap = useRef(createKeyHandlerMap([...keyMappings, ...coreMappings]));
//   const reducer = store(pluginsList);
//   const [state, dispatch] = useReducer<Reducer<State, InternalAction>>(
//     reducer,
//     initialState
//   );

//   const [lastSaveInMS, isDirty] = useStateSaver(state, backend);
//   useKeyHandler(keyMap.current, dispatch, state);

//   const configItems: ConfigurationItem[] = Array.from(
//     configItemMap().values()
//   ).reduce((acc, vs) => [...acc, ...vs], []);
//   useEffect(() => {
//     console.log(configItems.length);
//     const actionsToDispatch = configItems.reduce<InternalAction[]>(
//       (acc, item): InternalAction[] => {
//         const val = Config.get(item.key, state.config);

//         if (val !== undefined) {
//           switch (item.kind) {
//             case "string":
//               return [...acc, item.action(val as string)];
//             case "stringOpts":
//               return [...acc, item.action(val as string)];
//             case "number":
//               return [...acc, item.action(val as number)];
//             case "boolean":
//               return [...acc, item.action(val as boolean)];
//           }
//         }
//         // else, don't
//         return acc;
//       },
//       []
//     );

//     // Call dispatch
//     actionsToDispatch.forEach((action) => {
//       console.log(`Dispatching ${action.type}`);
//       dispatch(action);
//     });
//   }, []);

//   const Modal = state.modal;
//   const PluginView = state.windows.current.view.view;
//   const pluginState = currentViewState(state);

//   return {
//     lastSaveInMS,
//     isDirty,
//     numWindows: count(state.windows),
//     Modal: Modal && (() => <Modal state={state} dispatch={dispatch} />),
//     PluginView: () => (
//       <PluginView state={pluginState} dispatch={dispatch}></PluginView>
//     ),
//   };
// }
