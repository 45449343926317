/*

   ____        _ _      _   _                        _____               
  |  _ \      | | |    | | (_)                      / ____|              
  | |_) |_   _| | | ___| |_ _ _ __ ___   ___ ______| |     ___  _ __ ___ 
  |  _ <| | | | | |/ _ \ __| | '_ ` _ \ / _ \______| |    / _ \| '__/ _ \
  | |_) | |_| | | |  __/ |_| | | | | | |  __/      | |___| (_) | | |  __/
  |____/ \__,_|_|_|\___|\__|_|_| |_| |_|\___|       \_____\___/|_|  \___|

*/

import { createHandler } from "../../../keyboard";
import { State } from "../../../state";
import { Plugin } from "../../../state/plugin";
import Constants from "./actions/constants";
import { PLUGIN } from "./constants";
import view from "./view";
import { stateForTree } from "./utils/state-for-tree";

const pluginDef: Plugin = {
  name: "bulletime-core",
  description: "This is core UI plugin for Bulletime for Web",
  version: "0.1.0",
  contributions: { view },
  configurationItems: [],
};

const bulletimeAction = (type: string) => ({
  type: { type, plugin: PLUGIN, contribution: "view" },
  payload: undefined,
});

const noteIsSelected = (state: State) => {
  const s = stateForTree({
    storage: state.storage,
    tree: state.windows.current.tree,
    config: state.config,
    cache: state.cache,
  });
  return s && s.editState.state === "note";
};

export const keyMappings = [
  {
    key: "ArrowDown",
    handler: createHandler(bulletimeAction(Constants.navDown)),
  },
  { key: "ArrowUp", handler: createHandler(bulletimeAction(Constants.navUp)) },
  {
    key: "Ctrl+Enter",
    handler: createHandler(bulletimeAction("toggleNoteExpanded")),
  },
  { key: "Tab", handler: createHandler(bulletimeAction(Constants.indent)) },
  {
    key: "Shift+Tab",
    handler: createHandler(bulletimeAction(Constants.dedent)),
  },
  {
    key: "Meta+ArrowUp",
    handler: createHandler(bulletimeAction(Constants.toggleCollapsed)),
  },
  {
    key: "Shift+ArrowUp",
    handler: createHandler(bulletimeAction(Constants.moveUp)),
  },
  {
    key: "Shift+ArrowDown",
    handler: createHandler(bulletimeAction(Constants.moveDown)),
  },
  {
    key: "Meta+Enter",
    handler: createHandler(bulletimeAction(Constants.toggleCompleted)),
  },
  { key: "Meta+.", handler: createHandler(bulletimeAction("focusOn")) },
  {
    key: "Meta+,",
    handler: createHandler({ type: "focusOut", payload: undefined }),
  },
  {
    key: "Meta+'",
    handler: createHandler({ type: "focusHome", payload: undefined }),
  },
  {
    key: "Enter",
    handler: createHandler(bulletimeAction("addNote"), noteIsSelected),
  },
  {
    key: "Shift+Enter",
    handler: createHandler(bulletimeAction("addExpanded")),
  },
  {
    key: "Shift+Meta+Backspace",
    handler: createHandler(bulletimeAction("deleteNote"), noteIsSelected),
  },
  {
    key: "Meta+b",
    handler: createHandler(bulletimeAction("toggleRootBookmark")),
  },
];

export default { pluginDef };
