import { State, serialize } from "../../state";
import { useRef, useEffect, MutableRefObject, useState } from "react";
import { AppBackend } from "../services/backend";

export function useStateSaver(
  s: State,
  backend: AppBackend
): [number, boolean, () => void] {
  const lastSave: MutableRefObject<number> = useRef(Date.now());
  const lastState: MutableRefObject<State> = useRef(s);
  const interval: MutableRefObject<number | undefined> = useRef();

  const [time, setTime] = useState(Date.now() - lastSave.current);
  const [isDirty, setIsDirty] = useState(false);

  const cb = async () => {
    if (s === lastState.current) {
      if (isDirty) {
        setIsDirty(false);
        setTime(Date.now() - lastSave.current);
      }
      return;
    }
    await backend.save(serialize(s));
    lastSave.current = Date.now();
    lastState.current = s;
    setTime(0);
    setIsDirty(false);
  };

  useEffect(() => {
    interval.current = window.setInterval(cb, 15 * 1000);
    return () => clearInterval(interval.current);
  });

  useEffect(() => {
    setTime(Date.now() - lastSave.current);
    setIsDirty(s !== lastState.current);
  }, [s]);

  return [time, isDirty, cb];
}
