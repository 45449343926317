// rosetree
import * as T from "../../../../rosetree/tree";
import { Bullet as B } from "../../../../bulletime-ds/";

import { ContributionInputState } from "../../../../state/plugin/contributions/base";
import { BulletimeCoreState, defaultState } from "../model/state";
import { PLUGIN } from "../constants";

export const stateForTree = (
  state: ContributionInputState
): BulletimeCoreState => {
  return (state.storage[PLUGIN][B.id(T.label(state.tree))] ||
    defaultState) as BulletimeCoreState;
};
