import { Plugin } from "./plugin";
import { Dictionary, Serializable } from "../utils/core-types";

export interface Storage {
  bookmarks: string[];
  plugins: Dictionary<Dictionary<Serializable>>;
}

export const init = (ps: Plugin[]): Storage => {
  const plugins = ps.reduce((map, plugin) => {
    map[plugin.name] = {};
    return map;
  }, {});
  return {
    bookmarks: [],
    plugins
  };
};

export const toggleBookmark = (id: string, s: Storage): Storage => {
  if (s.bookmarks.includes(id)) {
    return { ...s, bookmarks: s.bookmarks.filter(bm => bm !== id) };
  } else {
    return { ...s, bookmarks: [id, ...s.bookmarks] };
  }
};

export const isBulletBookmarked = (id: string, s: Storage): boolean => {
  return s.bookmarks.includes(id);
};

export function serialize(storage: Storage): Object {
  return {
    ...storage
  };
}

export function deserialize(storage: Object): Storage {
  return {
    bookmarks: storage["bookmarks"] || [],
    plugins: storage["plugins"] || {}
  };
}
