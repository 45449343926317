import { State } from "../../state";
import { handleAction, Plugin } from "../../state/plugin";
import { Dictionary } from "../../utils/core-types";
import { InternalAction } from "./action";
import internalHandlers from "./handlers";
import rawData from "./test-data";
import { ContributionOutputState } from "../../state/plugin/contributions/base";

interface Handler {
  fn: Function;
}

function extractNewPluginState(
  actionResult:
    | ContributionOutputState
    | [ContributionOutputState, InternalAction]
): ContributionOutputState {
  return Array.isArray(actionResult) ? actionResult[0] : actionResult;
}

function extractNextAction(
  actionResult:
    | ContributionOutputState
    | [ContributionOutputState, InternalAction]
): InternalAction | undefined {
  return Array.isArray(actionResult) ? actionResult[1] : undefined;
}

export default function store(
  pluginsList: Plugin[]
): (state: State, action: InternalAction) => State {
  const plugins: Dictionary<Plugin> = pluginsList.reduce((map, p) => ({
    ...map,
    [p.name]: p,
  }), {});

  const internal: Map<string, (state: State, payload: any) => State> = internalHandlers.reduce((map, h) => {
    map.set(h.type, h.fn);
    return map;
  }, new Map());

  return function reducer(state: State, action: InternalAction) {
    logAction(action, state);
    if (typeof action.type === "string") {
      const handler = internal.get(action.type);
      return handler === undefined ? state : handler(state, action.payload);
    } else {
      const { plugin: pluginName, contribution, type } = action.type;
      const plugin = plugins[pluginName];
      if (plugin === undefined) {
        return state;
      }
      const actionResult = handleAction(
        plugin,
        contribution,
        type,
        {
          tree: state.windows.current.tree,
          config: state.config[pluginName] || {},
          storage: state.storage[pluginName] || {},
          cache: state.cache[pluginName] || {},
        },
        action.payload
      );

      const newPluginState = extractNewPluginState(actionResult);

      const newState = {
        ...state,
        windows: {
          ...state.windows,
          current: {
            ...state.windows.current,
            tree: newPluginState.tree,
          },
        },
        storage: {
          ...state.storage,
          [pluginName]: newPluginState.storage,
        },
        cache: {
          ...state.cache,
          [pluginName]: newPluginState.cache,
        },
      };

      const nextAction = extractNextAction(actionResult);

      return nextAction === undefined
        ? newState
        : reducer(newState, nextAction);
    }
  };
}

export function testData() {
  return rawData;
}

const coreActionLogStyle = "color: red; font-size: 16px";
const pluginActionLogStyle = "color: white; font-size: 16px";

function logAction(action: InternalAction, state: State) {
  if (typeof action.type === "string") {
    console.groupCollapsed(
      `%c [CORE ACTION][${action.type}]`,
      coreActionLogStyle
    );
    console.log(`%c [PAYLOAD] `.padEnd(80, "-"), coreActionLogStyle);
    console.dir(action.payload);
    console.log(`%c [BEFORE STATE] `.padEnd(80, "-"), coreActionLogStyle);
    console.dir(state);
    console.groupEnd();
  } else {
    console.groupCollapsed(
      `%c [PLUGIN ACTION][${action.type.plugin}.${action.type.contribution}.${action.type.type}]`,
      pluginActionLogStyle
    );
    console.log(`%c [PAYLOAD] `.padEnd(80, "-"), pluginActionLogStyle);
    console.dir(action.payload);
    console.log(`%c [BEFORE STATE] `.padEnd(80, "-"), coreActionLogStyle);
    console.dir(state);
    console.groupEnd();
  }
}
