import React, { RefObject, useRef } from "react";
import { Bullet as B } from "../../../../../../bulletime-ds";
import { FTWNode, nodeLabel } from "../../../model/flat-tree-window";
import { Bookmark } from "../Bookmark";

import styles from "./index.module.css";
import creators from "../../../actions/creators";
import { InternalAction } from "../../../../../store/action";
import { EditingState } from "../../../model/editing-state";
import { useEditStateScroll } from "../../../hooks/use-edit-state-scroll";
import { useEditStateFocus } from "../../../hooks/use-edit-state-focus";

const { note, expanded, id } = B;

interface RootProps {
  node: FTWNode<B.Bullet>;
  isBookmarked: boolean;
  editState: EditingState;
  dispatch: (action: InternalAction) => void;
}

const Root = ({ node, isBookmarked, dispatch, editState }: RootProps) => {
  const noteEl: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const expandedEl: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEditStateScroll(editState, id(nodeLabel(node)), noteEl, expandedEl, [
    editState,
  ]);
  useEditStateFocus(editState, id(nodeLabel(node)), noteEl, expandedEl, [
    editState,
  ]);

  return (
    <div
      style={{
        paddingLeft: "16px",
        paddingBottom: "32px",
        paddingRight: "16px",
      }}
    >
      <div style={{ display: "flex" }}>
        <h1
          contentEditable
          style={{ flex: "1" }}
          id={`bt-note-${id(nodeLabel(node))}`}
          onBlur={(e) =>
            dispatch(
              creators.noteUpdated(id(nodeLabel(node)), e.target.innerText)
            )
          }
          onClick={() => dispatch(creators.noteSelected(id(nodeLabel(node))))}
          dangerouslySetInnerHTML={{ __html: note(nodeLabel(node)) }}
          ref={noteEl}
        ></h1>
        <Bookmark
          bookmarked={isBookmarked}
          onClick={() => {
            dispatch(creators.toggleBookmark(id(nodeLabel(node))));
          }}
        ></Bookmark>
      </div>
      {expanded(nodeLabel(node)) !== undefined ? (
        <div
          contentEditable
          id={`bt-expanded-${id(nodeLabel(node))}`}
          onBlur={(e) =>
            dispatch(
              creators.expandedUpdated(id(nodeLabel(node)), e.target.innerText)
            )
          }
          onClick={() =>
            dispatch(creators.expandedSelected(id(nodeLabel(node))))
          }
          style={{ fontSize: "18px" }}
          className={
            expanded(nodeLabel(node)) !== undefined
              ? ""
              : styles.noExpandedContent
          }
          dangerouslySetInnerHTML={{
            __html: expanded(nodeLabel(node)) || "",
          }}
          ref={expandedEl}
        ></div>
      ) : (
        <div
          className={styles.noExpandedContent}
          onClick={() => dispatch(creators.addExpandedAtRoot())}
        >
          Leave more info here...
        </div>
      )}
    </div>
  );
};

export { Root };
