import { EditingState } from "./editing-state";

export interface BulletimeCoreState {
  readonly editState: EditingState;
  readonly rootIsBookmarked: boolean;
}

export const defaultState: BulletimeCoreState = {
  editState: { state: "none" },
  rootIsBookmarked: false
};
