import { Bullet as B } from "../bulletime-ds";
import { Tree } from "../rosetree/tree";
import { Maybe } from "../utils/maybe";
import { Plugin } from "./plugin";
import {
  deserialize as windowDeserialize,
  init as windowInit,
  serialize as windowSerialize,
  Window,
} from "./window";

export interface Windows {
  readonly before: Window[];
  readonly current: Window;
  readonly after: Window[];
}

export function init(tree: Tree<B.Bullet>, plugin: Plugin): Windows {
  return {
    before: [],
    current: windowInit(tree, plugin),
    after: [],
  };
}

export function newWindowAt(
  tree: Tree<B.Bullet>,
  plugin: Plugin,
  w: Windows
): Windows {
  return {
    ...w,
    before: [w.current, ...w.before],
    current: windowInit(tree, plugin),
  };
}

export function closeWindow(w: Windows): Maybe<Windows> {
  if (w.before.length === 0 && w.after.length === 0) {
    return;
  } else {
    if (w.before.length > 0) {
      return {
        current: w.before[0],
        before: w.before.slice(1),
        after: w.after,
      };
    } else {
      return {
        current: w.after[0],
        after: w.after.slice(1),
        before: w.before,
      };
    }
  }
}

export function nextWindow(w: Windows): Windows {
  const next = w.after[0];
  if (next === undefined) {
    // Attempt to wrap around
    const next = w.before[w.before.length - 1];
    if (next === undefined) {
      return w;
    }
    return {
      current: next,
      after: [...w.before.slice(0, -1).reverse(), w.current],
      before: [],
    };
  }
  return {
    current: next,
    after: w.after.slice(1),
    before: [w.current, ...w.before],
  };
}

export function previousWindow(w: Windows): Windows {
  const next = w.before[0];
  if (next === undefined) {
    // Attempt to wrap around
    const next = w.after[w.after.length - 1];
    if (next === undefined) {
      return w;
    }
    return {
      current: next,
      before: [...w.after.slice(0, -1).reverse(), w.current],
      after: [],
    };
  }
  return {
    current: next,
    before: w.before.slice(1),
    after: [w.current, ...w.after],
  };
}

export function windowAtIndex(idx: number, w: Windows): Windows {
  const allWindows = [...w.before, w.current, ...w.after];
  const atIndex = allWindows[idx];
  if (atIndex === undefined) {
    return w;
  } else {
    return {
      current: atIndex,
      before: allWindows.slice(0, Math.max(0, idx)),
      after: allWindows.slice(idx + 1),
    };
  }
}

export const count = (w: Windows): number =>
  w.before.length + 1 + w.after.length;

export function serialize(w: Windows, plugins: Plugin[]): Object {
  return {
    before: w.before.map((window) => windowSerialize(window, plugins)),
    current: windowSerialize(w.current, plugins),
    after: w.after.map((window) => windowSerialize(window, plugins)),
  };
}

export function deserialize(w: Object, plugins: Plugin[]): Windows {
  return {
    before: w["before"].map((window) => windowDeserialize(window, plugins)),
    current: windowDeserialize(w["current"], plugins),
    after: w["after"].map((window) => windowDeserialize(window, plugins)),
  };
}
