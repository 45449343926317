import { Bullet as B } from "../bulletime-ds";
import { fromDisplay, toTree } from "../bulletime-ds/bullet-tree";
import { InternalAction } from "../core/store/action";
import { Tree } from "../rosetree/tree";
import { Cache } from "./cache";
import * as Conf from "./config";
import * as Plugin from "./plugin";
import * as Storage from "./storage";
import * as User from "./user";
import * as Win from "./windows";
import { ContributionInputState } from "./plugin/contributions/base";

export interface State {
  /**
   * True to it's name, this is the canonical tree.
   * Updates to subtrees held by plugin views, services,
   * and providers should be updated all the time.
   */
  canonical: Tree<B.Bullet>;
  /**
   * This is a non-empty list of "windows", or different
   * views held in memory.  These can be bound to different
   * plugins.
   */
  windows: Win.Windows;
  /**
   * This is a place where user-configurable values can be
   * stored.  The plugin itself has only read only access to
   * these values, with the manipulation of them happening in
   * the ConfigPanel, which is part of the core UI.
   */
  config: Conf.Config;
  /**
   * This is information about the user, very sparse until
   * the Bulletime backend is created.
   */
  user: User.User;
  /**
   * This is a list of all the currently installed plugins.
   */
  plugins: Plugin.Plugin[];
  /**
   * This is a place for plugins to store state, and other values
   * that are not necessarily user-configurable which they wish to
   * be persisted.
   */
  storage: Storage.Storage;
  /**
   * This is a place for plugins to store temporary values that do
   * not need to be persisted.  The values here can be freed at any
   * time.  Good for caching and storing non-critical pieces of view
   * state.
   */
  cache: Cache;
  /**
   * An optional modal component.  This component will only have
   * access to the local state, be it plugin state for plugin
   * modals, or global state for Core UI modals like WindowSwitcher.
   */
  modal?: React.FunctionComponent<{
    state: State;
    dispatch: (action: InternalAction) => void;
  }>;
  /*
  services: Service[];
  panel?: React.FunctionComponent<{}>;

  */
}

const defaultTree = toTree(
  fromDisplay(
    `
* Home
  Welcome to Bulletime!
\t> This is your first note, try editing me.
\t  You can also leave notes. Switch to me with Ctrl+Enter.
\t* We support org markup in your notes
\t\t* We have *bold*
\t\t* And /italic/
\t\t* As well as _underline_
\t\t* You can add :tags:
\t\t* You can also paste in URLs, like https://bulleti.me
\t* Here are some more useful commands
\t\t* [Enter] - Create a new note
\t\t  Only when you are editing a note
\t\t* Ctrl+Cmd+n - Create a new window
\t\t* Cmd+j - Previous window
\t\t* Cmd+k - Next window
\t\t* Cmd+/ - Show window switcher
\t\t* Escape - Close any modal
\t\t* Down arrow - Select the next note
\t\t* Up arrow - Select the previous note
\t\t* Ctrl+Enter - Toggle between the note and the expanded section beneath
\t\t* Tab - Indent the note
\t\t* Shift+Tab - Dedent the note
\t\t* Meta+ArrowUp - Collapse the children of the current note
\t\t* Shift+ArrowUp - Move the note up.
\t\t  This only works amongst its siblings, dedent to move to parent's level
\t\t* Shift+ArrowDown - Move the note down
\t\t  The same as above
\t\t* Meta+Enter - Mark the note as complete
\t\t* Meta+. - Focus on this note and its children
\t\t* Meta+, - Focus on the parent of the currently focused note
\t\t* Meta+' - Go Home
\t\t* Shift+Meta+Backspace - Delete the note
\t\t  Only when the note is selected
\t\t* Meta+b - Toggle bookmark for the currently focused note
`.trim()
  )
);

export function init(
  plugins: Plugin.Plugin[],
  mountViewFrom: string,
  withTree?: Tree<B.Bullet>
): State {
  const initialTree: Tree<B.Bullet> = withTree ? withTree : defaultTree;
  const initialViewPlugin = plugins.find((p) => p.name === mountViewFrom);
  if (!initialViewPlugin) {
    throw new Error(`Can't mount from ${mountViewFrom}`);
  }

  return {
    canonical: initialTree,
    windows: Win.init(initialTree, initialViewPlugin),
    config: Conf.init(),
    user: {
      name: "Anthony",
      email: "anthony.bullard@gmail.com",
    },
    plugins,
    storage: Storage.init(plugins),
    cache: {},
    modal: undefined,
  };
}

export function newWindow(state: State): State {
  return {
    ...state,
    windows: Win.newWindowAt(state.canonical, state.plugins[0], state.windows),
  };
}

export function currentViewState(state: State): ContributionInputState {
  return {
    tree: state.windows.current.tree,
    storage: state.storage[state.windows.current.plugin] || {},
    config: state.config[state.windows.current.plugin] || {},
    cache: {},
  };
}

export function serialize(state: State): string {
  return JSON.stringify({
    canonical: state.canonical,
    config: Conf.serialize(state.config),
    user: state.user,
    plugins: state.plugins.map((p) => p.name),
    storage: Storage.serialize(state.storage),
    windows: Win.serialize(state.windows, state.plugins),
    TIMESTAMP: Date.now(),
  });
}

export function deserialize(
  plugins: Plugin.Plugin[],
  state: Record<string, any>
): State {
  return {
    canonical: state["canonical"],
    config: Conf.deserialize(state["config"]),
    user: state["user"],
    plugins: (state["plugins"] as string[]).reduce<Plugin.Plugin[]>(
      (acc: Plugin.Plugin[], p: string): Plugin.Plugin[] => {
        const match = plugins.find((plug) => plug.name === p);
        return match !== undefined ? [...acc, match] : acc;
      },
      []
    ),
    storage: Storage.deserialize(state["storage"]),
    windows: Win.deserialize(state["windows"], plugins),
    cache: {},
  };
}
