// Maybe
// ----
// Reasonable subset of functionality for the Maybe monad
// operating on an untagged union.
export type Maybe<T> = T | undefined;

export const andThen = <T>(fn: (z: T) => Maybe<T>, mz: Maybe<T>): Maybe<T> =>
  mz === undefined ? undefined : fn(mz);

export const pipe = <T>(
  someT: T,
  fns: Array<(z: T) => Maybe<T>>,
  debug?: (t: Maybe<T>) => void
): Maybe<T> => {
  if (fns.length > 0) {
    const newT: Maybe<T> = fns[0](someT);
    if (debug !== undefined) {
      debug(newT);
    }
    if (newT === undefined) {
      return undefined;
    }
    return pipe(
      newT,
      fns.slice(1),
      debug
    );
  }

  return someT;
};

export const filter = <T>(pred: (t: T) => boolean, someT: T): Maybe<T> =>
  pred(someT) ? someT : undefined;

export const map = <T, S>(fn: (t: T) => S, m: Maybe<T>): Maybe<S> =>
  m === undefined ? undefined : fn(m);
