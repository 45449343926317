import React, { useState, useRef, RefObject, useEffect } from "react";
import { Config, get as confGet } from "../../../state/config";
import {
  BooleanConfigurationItem,
  ConfigurationItem,
  ConfigurationItemBase,
  NumberConfigurationItem,
  StringConfigurationItem,
  StringOptionsConfigurationItem,
  KeyboardShortcutConfigurationItem,
} from "../../../state/plugin/configuration-item";
import { DispatchFunction } from "../../../state/plugin/contributions/view";
import { keyboardEventToKey } from "../../../keyboard";
import styles from "./index.module.css";

interface Props {
  itemMap: Map<string, ConfigurationItem[]>;
  config: Config;
  dispatch: DispatchFunction;
}

export default function({ itemMap, config, dispatch }: Props) {
  return (
    <div className={styles.panel}>
      <h2>Configuration</h2>
      <dl>
        {Array.from(itemMap.entries()).map(([module, items]) => {
          return (
            <React.Fragment key={module}>
              <dt>{module}</dt>
              {items.map((item) => (
                <dd key={item.key}>
                  <div className={styles.item}>
                    <label>{item.label}</label>
                    <ConfigComponent
                      item={item}
                      config={config}
                      dispatch={dispatch}
                    />
                  </div>
                  <div className={styles.description}>{item.description}</div>
                </dd>
              ))}
            </React.Fragment>
          );
        })}
      </dl>
    </div>
  );
}

function ConfigComponent({
  item,
  config,
  dispatch,
}: {
  item: ConfigurationItem;
  config: Config;
  dispatch: DispatchFunction;
}) {
  let Component;
  let updater;
  function update<T>(item: ConfigurationItemBase<T>) {
    return (val: T) => {
      console.log(
        `val is '${val}' and it's type is ${typeof val} and the item key is ${
          item.key
        }`
      );
      console.log(`config is currently:`);
      console.log(config);
      dispatch({ type: "updateConfig", payload: { key: item.key, val } });
      dispatch(item.action(val));
    };
  }
  switch (item.kind) {
    case "string":
      Component = StringConfig;
      updater = update<string>(item);
      break;
    case "number":
      Component = NumberConfig;
      updater = update<number>(item);
      break;
    case "boolean":
      Component = BooleanConfig;
      updater = update<boolean>(item);
      break;
    case "stringOpts":
      Component = StringOptionConfig;
      updater = update<string>(item);
      break;
    case "keyboardShortcut":
      Component = KeyboardShortcutConfig;
      updater = update<string>(item);
      break;
  }

  return (
    <Component
      item={item}
      current={confGet(item.key, config) || item.defaultVal}
      update={updater}
    />
  );
}

interface ConfigComponentProps<T, S extends ConfigurationItemBase<T>> {
  item: S;
  current: T;
  update: (T) => void;
}

function StringConfig({
  item,
  current,
  update,
}: ConfigComponentProps<string, StringConfigurationItem>) {
  const pattern = {
    pattern: item.pattern !== undefined ? item.pattern.source : undefined,
  };
  return (
    <input
      type="text"
      defaultValue={current}
      {...pattern}
      onBlur={(e) => update(e.target.value)}
    />
  );
}

function NumberConfig({
  item,
  current,
  update,
}: ConfigComponentProps<number, NumberConfigurationItem>) {
  return (
    <input
      type="number"
      defaultValue={current}
      onBlur={(e) =>
        console.log(
          `New number val for ${item.key}: ${parseInt(e.target.value)}`
        )
      }
    />
  );
}

function BooleanConfig({
  item,
  current,
  update,
}: ConfigComponentProps<boolean, BooleanConfigurationItem>) {
  return (
    <select
      defaultValue={current ? 1 : 0}
      onChange={(e) =>
        console.log(`New boolean value for ${item.key}: ${!!e.target.value}`)
      }
    >
      <option value={1}>True</option>
      <option value={0}>False</option>
    </select>
  );
}

function StringOptionConfig({
  item,
  current,
  update,
}: ConfigComponentProps<string, StringOptionsConfigurationItem>) {
  return (
    <select defaultValue={current} onChange={(e) => update(e.target.value)}>
      {item.options.map((opt, idx) => (
        <option value={opt} key={item.key + ` ${opt} ${idx}`}>
          {opt}
        </option>
      ))}
    </select>
  );
}

function KeyboardShortcutConfig({
  item,
  current,
  update,
}: ConfigComponentProps<string, KeyboardShortcutConfigurationItem>) {
  const [isSetting, setIsSetting] = useState(false);
  const [shortcut, setShortcut] = useState("");
  const shortcutRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const shortcutHandler = function(e: KeyboardEvent) {
    setShortcut(keyboardEventToKey(e));
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    const refCurrent = shortcutRef.current;
    if (refCurrent) {
      (refCurrent.firstChild! as HTMLInputElement).focus();
      (refCurrent.firstChild! as HTMLInputElement).addEventListener(
        "keydown",
        shortcutHandler.bind(refCurrent)
      );
    }

    return () => {
      if (refCurrent) {
        (refCurrent.firstChild! as HTMLInputElement).removeEventListener(
          "keydown",
          shortcutHandler.bind(refCurrent)
        );
      }
    };
  }, [isSetting]);
  return isSetting === false ? (
    <button
      className={styles["keyboardShortcut--button"]}
      onClick={() => setIsSetting(!isSetting)}
    >
      {current || "Click to set"}
    </button>
  ) : (
    <div className={styles["keyboardShortcut--set-area"]} ref={shortcutRef}>
      <input
        placeholder={shortcut || "Set now"}
        value={undefined}
        onChange={() => {}}
        onBlur={() => {
          setIsSetting(false);
          update(shortcut);
        }}
      />
    </div>
  );
}
