import React from "react";

interface SavedStatusProps {
  time: number;
  isDirty: boolean;
}

export default ({ time, isDirty }: SavedStatusProps) => {
  const timeString = calculateTimeString(time, isDirty);
  return (
    <i
      className={"material-icons" + (isDirty ? "pulsing" : "")}
      title={timeString}
    >
      {isDirty ? "import_export" : "check"}
    </i>
  );
};

function calculateTimeString(time: number, isDirty): string {
  if (!isDirty) {
    return "All changed saved.";
  }
  if (time < 1000) {
    return "Last saved just now.";
  } else if (time < 60 * 1000) {
    return "Last saved seconds ago.";
  } else if (time < 60 * 60 * 1000) {
    return ` ${Math.floor(time / 60 / 1000)} minutes ago.`;
  } else {
    return " Last saved more than an hour ago.";
  }
}
