import { State, serialize } from "../../state";
import { BackendResult, Backend } from "./backend";
import constants from "../constants";

export function init(defaultState: State): Promise<BackendResult<Backend>> {
  const load = async (): Promise<BackendResult<string>> => {
    const data = window.localStorage.getItem(
      constants.bulletimeLocalStorageKey
    ) as string;
    if (data !== null) {
      await save(serialize(defaultState));
    }
    return Promise.resolve(["ok", data]);
  };

  const save = (state: string): Promise<BackendResult<null>> => {
    window.localStorage.setItem(constants.bulletimeLocalStorageKey, state);
    return Promise.resolve(["ok", null]);
  };

  const signout = (): Promise<BackendResult<null>> =>
    Promise.resolve(["ok", null]);

  return Promise.resolve([
    "ok",
    {
      load,
      save,
      signout
    }
  ]);
}
