import * as Tree from "../../bulletime-ds/bullet-tree";
import plugins from "../plugins";
import { init, State } from "../../state";
import { tree } from "../../rosetree/zipper";

const treeDisplay = `
> Home
  Welcome to Bulletime!
\t* Write your first note below.  Just click on me and hit [Enter].
`.trim();

export const createTree = () => {
  return Tree.fromDisplay(treeDisplay);
};

export const testState: State = init(
  plugins,
  "bulletime-core",
  tree(createTree())
);

const rawData = JSON.stringify(testState);

export default rawData;
