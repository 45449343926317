import React from "react";
import { Bullet, id } from "../../../bulletime-ds/bullet";
import { breadcrumbs, fromTree, to } from "../../../bulletime-ds/bullet-tree";
import { label, Tree } from "../../../rosetree/tree";

interface BreadcrumbsProps {
  tree: Tree<Bullet>;
  current: Tree<Bullet>;
  onCrumbClicked: (string) => void;
}

const Breadcrumbs = ({ tree, current, onCrumbClicked }: BreadcrumbsProps) => {
  const bulletTree = to(id(label(current)), fromTree(tree));
  const crumbs = breadcrumbs(bulletTree);
  return (
    <>
      {crumbs
        .map((crumb, idx) => (
          <span
            className="crumb"
            key={`crumb${idx}`}
            onClick={() => onCrumbClicked(crumb.id)}
          >
            {" "}
            > {crumb.title}{" "}
          </span>
        ))
        .reverse()
        .slice(1)}
    </>
  );
};

export default Breadcrumbs;
