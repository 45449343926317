import { Dictionary, Serializable } from "../utils/core-types";

export interface Config {
  core: Dictionary<Serializable>;
  plugins: Dictionary<Dictionary<Serializable>>;
}

export function init(): Config {
  return {
    core: {},
    plugins: {},
  };
}

export function serialize(c: Config): Object {
  return c;
}

export function deserialize(c: Object): Config {
  return {
    core: c["core"],
    plugins: c["plugins"],
  };
}

export function update(key: string, val: any, c: Config) {
  console.log(`updating ${key} with ${val}`);
  const keyParts = key.split(".");
  if (keyParts[0] === "core") {
    console.log("core update");
    return {
      ...c,
      core: {
        ...c.core,
        [keyParts.slice(1).join(".")]: val,
      },
    };
  } else {
    const plugin = keyParts.slice(1)[0];
    const pluginKey = keyParts.slice(2).join(".");
    const existingConf = c.plugins[plugin];
    return {
      ...c,
      plugins: {
        ...c.plugins,
        [plugin]: {
          ...existingConf,
          [pluginKey]: val,
        },
      },
    };
  }
}

export function get(key: string, c: Config): Serializable | undefined {
  const keyParts = key.split(".");
  try {
    if (keyParts[0] === "core") {
      console.log("core update");
      return c.core[keyParts.slice(1).join(".")];
    } else {
      const plugin = keyParts.slice(1)[0];
      const pluginKey = keyParts.slice(2).join(".");
      const existingConf = c.plugins[plugin];
      return existingConf[pluginKey];
    }
  } catch (e) {
    return undefined;
  }
}
