import { Backend, BackendResult } from "./backend";
import { State } from "../../state";

const BASE = "https://www.googleapis.com/";
const DISCOVERY_DOCS = [`${BASE}discovery/v1/apis/drive/v3/rest`];
const SCOPES = `${BASE}auth/drive.appdata`;
const FILES_PATH = "/upload/drive/v3/files/";
const API_KEY = "AIzaSyDl-0VbclnI5VmPMd6-51xlVppRvxPxJP0";
const CLIENT_ID =
  "238525470563-1pd1kl1h8ho4ipj46reo1if4hjri0aam.apps.googleusercontent.com";
const LS_KEY = "GDRIVE";

const filename = "data.json";

export const isFound = () => window.localStorage.getItem(LS_KEY) !== null;

export const init = async (
  defaultData: string
): Promise<BackendResult<Backend>> => {
  // Ensure we have the API script loaded.
  await new Promise((resolve, reject) => {
    if (!window.gapi) {
      console.warn("GAPI not loaded....waiting...");
      if (document.querySelector("gd-api") === null) {
        const scriptTag = document.createElement("script");
        scriptTag.src = "https://apis.google.com/js/api.js";
        scriptTag.id = "gd-api";
        scriptTag.onload = e => {
          while (!window.gapi) {}
          resolve();
        };
        document.body.appendChild(scriptTag);
      }
    } else {
      resolve();
    }
  });

  // Load auth client
  await new Promise((resolve, reject) => {
    window.gapi.load("client:auth2", resolve);
  });

  // Init auth client
  const initParams = {
    apiKey: API_KEY,
    clientId: CLIENT_ID,
    discoveryDocs: DISCOVERY_DOCS,
    scope: SCOPES
  };

  try {
    await window.gapi.client.init(initParams);
  } catch (e) {
    console.trace("Could not initialize auth client");
    console.error(e);
    return ["err", e.details];
  }

  if (
    window.gapi.auth2.getAuthInstance().isSignedIn.get() === false ||
    !window.gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .hasGrantedScopes(SCOPES)
  ) {
    await window.gapi.auth2.getAuthInstance().signIn({
      scope: SCOPES
    });
  }

  window.gapi.auth2
    .getAuthInstance()
    .currentUser.get()
    .hasGrantedScopes(SCOPES);

  // Check signin
  try {
    await new Promise((resolve, reject) => {
      const start = Date.now();
      const timeout = 60 * 1000;
      while (
        window.gapi.auth2.getAuthInstance().isSignedIn.get() === false &&
        Date.now() - start < timeout
      ) {}

      if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
        window.localStorage.setItem(LS_KEY, 'true');
        resolve();
      } else {
        reject("Could not initialize Google Drive Auth service");
      }
    });
  } catch (e) {
    return ["err", e.message];
  }

  // Now get the file and construct the Backend
  try {
    const response = await window.gapi.client.drive.files.list({
      spaces: "appDataFolder",
      pageSize: 1,
      q: `name = "${filename}"`,
      fields:
        "files(capabilities/canEdit,id,isAppAuthorized,modifiedTime,name),nextPageToken"
    });

    const files = response.result.files
      ? response.result.files.filter(f => f.isAppAuthorized)
      : [];

    if (files.length === 0) {
      try {
        const params = {};
        const headers = { "Content-Type": "application/json" };

        const body = {
          name: "data.json",
          parents: ["appDataFolder"]
        };

        const newFile = await window.gapi.client.request<
          gapi.client.drive.File
        >({
          params,
          headers,
          path: "/drive/v3/files",
          method: "POST",
          body
        });

        const file = { id: newFile.result.id!, name: newFile.result.name! };
        const backend = GoogleBackend(file);
        await backend.save(defaultData);
        return ["ok", GoogleBackend(file)];
      } catch (e) {
        return ["err", e.message];
      }
    } else {
      return ["ok", GoogleBackend({ id: files[0].id!, name: files[0].name! })];
    }
  } catch (e) {
    return ["err", e.message];
  }
};

interface DriveFile {
  id: string;
  name: string;
}

const GoogleBackend = (file?: DriveFile): Backend => {
  console.log(`Creating GoogleDriveBackend with file`);
  console.dir(file);
  return {
    save: async (data: string) => {
      if (file === undefined) {
        return Promise.reject(["err", "Not initialized"]);
      }

      const path = `${FILES_PATH}${encodeURIComponent(file.id)}`;
      const method = "PATCH";

      try {
        await writeFile(method, path, data);

        return Promise.resolve(["ok", null]);
      } catch (e) {
        return Promise.reject(["err", e.message]);
      }
    },
    load: async () => {
      if (file === undefined) {
        return ["err", "Not initialized"];
      }

      try {
        const response = await window.gapi.client.drive.files.get({
          fileId: file.id,
          alt: "media"
        });
        return ["ok", response.body];
      } catch (e) {
        return ["err", e.message];
      }
    },
    signout: () => {
      window.gapi.auth2.getAuthInstance().signOut();
      window.localStorage.removeItem(LS_KEY);
      return Promise.resolve(["ok", null]);
    }
  };
};

const writeFile = async (
  method: string,
  path: string,
  data: State | string
) => {
  const params = { uploadType: "media" };
  const headers = { "Content-Type": "application/json" };

  const body = typeof data === "string" ? JSON.parse(data) : data;

  return await window.gapi.client.request<gapi.client.drive.File>({
    params,
    headers,
    path,
    method,
    body
  });
};
